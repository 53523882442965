.slider__auth {
  height: 100%;

  & > div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    & > a {
      background: none !important;
      box-shadow: none !important;
    }

    & > ol {
      position: absolute;
      left: 50% !important;
      top: auto;
      right: auto;
      bottom: 20px !important;
      margin-left: 0 !important;
      transform: translate(-50%, -50%) !important;
    }

    & > div {
      position: absolute !important;
      // overflow-x: none !important;
      // overflow-y: visible !important;
      width: 75%;
      height: 100%;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;

      img {
        background-size: cover;
        display: inline-block;
        background-position: center;
        width: 1080px !important;
        // height: 75vh !important;
        padding-bottom: 60px !important;
      }

      & > div > div {
        top: 88% !important;
        left: 50%;
        transform: translateX(-50%);
        width: 85% !important;
        p {
          color: #1d86f1 !important;
          font-size: 2.3rem !important;
          text-shadow: none !important;
          font-weight: bold;
        }
      }
    }
  }
}
