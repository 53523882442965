@import "../../../../../styles/theme/app/variables";

.column {
  margin-left: 2%;
  margin-right: 2%;
}
.contentbody {
  margin: 20px 20px;
}
.button {
  width: 10%;
  float: right;
  height: 5%;
  margin-bottom: 3%;

  @media only screen and (max-width: 400px) {
    width: 100px;
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.loader-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  background: #000;
  opacity: 0.5;
  left: 0px;
  z-index: 9999;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  height: auto;
  color: white;
  z-index: 1000;
  transform: translate(-50%, -50%);
  i {
    font-size: 4rem;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
  }
}
.advancedbtn {
  width: 150px;
  margin: 20px 0px;
  float: right;
  margin-right: 20px;
}
.configbutton {
  width: 70px !important;
  margin-left: 30%;
}
.configbuttontext {
  margin-left: 10px;
}
.submit {
  float: right;
}
.cancelbtn {
  width: 70px;
}
