@import "../../styles/theme/app/variables.scss";

.datePicker {
  width: 100%;
  > span > div > input {
    height: $main-input-height;
    color: $main-input-clr;
    border: 1px solid $main-input-border-clr !important;
    border-radius: $main-input-border-radius;
    padding: $main-input-padding;
    font-size: $main-input-font-size;
    font-weight: $main-input-font-weight;
    transition: all $main-input-transition-duration
      $main-input-transition-timing-function;
    width: 100%;
    background-color: $main-input-background-clr;
    font-size: $main-input-font-size;
    font-weight: $main-input-font-weight;
    outline: none;

    &:focus {
      border: 1px solid $main-input-border-clr-focus;
      background-color: $main-input-background-clr-focus;
      color: $main-input-clr-focus;
    }
    &:hover {
      border: 1px solid $main-input-border-clr-hvr !important;
    }
    &:disabled {
      background-color: $main-input-background-clr;
      color: $main-input-clr;
      border: 1px solid $main-input-border-clr;
    }
    &::placeholder {
      color: $main-input-placeholder-clr;
      font-size: $main-input-placeholder-font-size;
      font-weight: $main-input-placeholder-font-weight;
    }
  }
}
