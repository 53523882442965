@import "../../styles/theme/app/variables.scss";

.selectContainer {
  position: relative;

  .disabledMessage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000;
    font-size: 14px;
    text-align: center;
  }
}

.label {
  color: $dark-font-clr;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
}
