.languageSwitcher {
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  color: rgba(113, 113, 113, 1);
  display: flex;
  align-items: center;
  gap: 6px;
}
