@import "../../styles/theme/app/variables.scss";

.label {
  color: $dark-font-clr;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 5px;

  .icon {
    color: $main-btn-clr;
    width: 25px;
    height: 25px;
    font-size: 25px;
    transition: color $main-animation-duration $main-animation-timing-function;

    &:hover {
      color: $main-btn-clr-hvr;
    }
  }
}
