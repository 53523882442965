@import "../../../../styles/theme/app/variables";
.column {
  margin-left: 5%;
}
.contentbody {
  margin-top: 20px;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  background: #000;
  opacity: 0.5;
  left: 0px;
  z-index: 9999;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  height: auto;
  color: white;
  z-index: 1000;
  transform: translate(-50%, -50%);
  i {
    font-size: 4rem;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
  }
}
.button {
  width: 30%;
  float: left;
  height: 5%;
  @media only screen and (max-width: 400px) {
    width: 100px;
  }
}
.advancedbtn {
  width: 150px;
  margin: 20px 0px;
  float: left;
}
.configbutton {
  width: 100px !important;
  margin-left: 30%;
}
.configbuttontext {
  margin-left: 10px;
}
.submit {
  width: 100px;
  float: left;
}
.cancelbtn {
  width: 100px;
  float: right;
}
.Form {
  width: 40%;
  @media only screen and (max-width: 860px) {
    width: 100%;
  }
}

.details dt {
  width: 26% !important;
}
.HPlatForms {
  // width: max-content;
}
.HPlatForms input {
  border-radius: 5px !important;
  margin-top: 10px !important;
  height: 48px;
}
.PlatForms {
  width: 40%;
  @media only screen and (max-width: 860px) {
    width: 100%;
  }
}
.PlatForms input {
  border-radius: 5px !important;
  margin-top: 10px !important;
  height: 48px;
}
.PlatForms select {
  border-radius: 5px !important;
  margin-top: 10px !important;
  height: 48px !important;
  width: 150% !important;
}
.NPlatForms {
  width: 40%;
  margin-bottom: 42px;
  @media only screen and (max-width: 860px) {
    width: 100%;
  }
}

.NPlatForms input {
  border-radius: 5px !important;
  margin-top: 10px !important;
  margin-bottom: 20px !important;
  height: 48px;
}
.NPlatForms button {
  display: none;
}
.alert {
  background-color: #013874 !important;
  color: #ffffff !important;
  position: absolute;
  top: 0%;
  left: 30%;
  width: 30%;
  z-index: 5;
  @media only screen and (max-width: 415px) {
    width: 100%;
    margin: auto;
    position: absolute;
    top: 0%;
    left: 0%;
  }
}
.noappbackend {
  text-align: center;
  margin: 100px 0px;
}
.pointer {
  cursor: pointer;
}

.textSTY {
  color: #353535;
}

.deleteButton {
  background-color: #ffffff !important;
  color: #1d86f1 !important;
  font-size: 16px;
  border-color: #1d86f1 !important;
  padding: 12px;
  border-radius: 4px;
}
.populateButton {
  background-color: #1d86f1 !important;
  color: #ffffff !important;
  font-size: 16px;
  border-color: #ffffff !important;
  width: 161px;
  padding: 12px !important;
  border-radius: 4px;
  opacity: 1 !important;
}

.filedss {
  margin-top: 10px;
}
.toolTipStyle {
  box-shadow: 0px 17px 10px -10px rgba(0, 0, 0, 0.35);
  max-width: 200px;
  width: fit-content;
  height: auto;
  position: absolute;
  padding: 8px;
  font-size: 10px;
  border-radius: 4px;
  color: #fff;
  background: #222;
  border: 1px solid transparent;
  bottom: 64px;
  margin-left: 5px;
  display: inline-block;
  z-index: 2;
}

/* Tooltip text */
.tooltiptext {
  width: 100%;
  background-color: #272727 !important;
  color: #fff !important;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  top: -18px;
  margin-left: 9px;
  z-index: 1;
  font-size: 13px;
  padding: 8px 21px;
}

.passwordInput {
  max-width: 390px;
}
