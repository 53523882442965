@import "../../../styles/theme/app/variables.scss";

.wrapper {
  height: 5rem;
  width: 100%;
  position: relative;
  background: rgba(255, 255, 255, 0.5);
  input {
    height: 5rem;
    border-radius: 5px !important;
    font-size: 1.5rem !important;
  }
  & > span:last-of-type {
    text-align: right !important;
  }
}

.times-icon {
  position: absolute;
  top: 1.4rem !important;
  cursor: pointer;
  z-index: 0 !important;
  right: 4.8rem !important;
  font-size: 2.5rem !important;
}

.placeholder-icon {
  position: absolute;
  top: 1.4rem;
  right: 1rem;
  cursor: pointer;
  z-index: 4;
  right: 4.8rem;
  width: 27px;
  height: 25px;
  font-size: 2.5rem;
}

.notification-icon {
  position: absolute;
  top: 1.4rem !important;
  right: 1rem !important;
  font-size: 2.5rem !important;
}
.space {
  margin-top: 10px;
}

.readOnly {
  text-overflow: ellipsis;
  overflow: hidden;
  height: 36px;
  width: 100%;
  padding: 0 10px;
  border: 1px solid #e5e5e5;
}

.file {
  position: absolute;
  top: 0;
  left: 0;
  height: 36px;
  width: 100%;
  z-index: 0;
  cursor: pointer;
  opacity: 0;
}
