@import "../../styles/theme/app/variables.scss";

.content {
  min-height: calc(
    100vh - 85px - 65px
  ); // 85px = header height, 65px = footer height
}

.onboarding {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 2em;
  color: #ffffff;
  font-size: 26px;

  width: 100%;
  padding: 15px 13px 15px 6px;
  .circle {
    .gradientCircle {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 55px;
      width: 55px;
      border-radius: 24px;
      background: linear-gradient(180deg, #004da0, #ed1650);

      .whiteCircle {
        background: #fff;
        box-shadow: 0 20px 50px rgba(49, 49, 49, 0.15);
        border-radius: 50%;
        width: 42px;
        padding: 0em;
        height: 42.35px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      span {
        color: #024da1;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
  .text {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #0078ff;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 16px;

    &:hover {
      color: #ffffff;
    }
    p {
      width: 50%;
    }
    svg {
      font-size: 22px;
    }
  }
}

@media (max-width: 767px) {
  .onboarding {
    p {
      display: none;
    }
  }
}
