@import "../../../styles/theme/app/variables";

.wrapper {
  height: 48px;
  width: 100%;
  position: relative;
  background: rgba(255, 255, 255, 0.5);
  span,
  .input-group {
    display: flex !important;
  }
}
.space {
  margin-top: 10px;
}
.readOnly {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  padding: $main-input-padding;
  border: 1px solid $main-input-border-clr !important;
  height: $main-input-height;
  border-radius: $main-input-border-radius;
  font-size: $main-input-font-size;
  font-weight: $main-input-font-weight;
  color: $main-input-clr;
  background-color: $main-input-background-clr;
  transition: all $main-input-transition-duration
    $main-input-transition-timing-function;
  &:focus {
    border: 1px solid $main-input-border-clr-focus !important;
    background-color: $main-input-background-clr-focus;
    color: $main-input-clr-focus;
  }

  &::placeholder {
    color: $main-input-placeholder-clr;
    font-size: $main-input-placeholder-font-size;
    font-weight: $main-input-placeholder-font-weight;
  }

  &:hover {
    border: 1px solid $main-input-border-clr-hvr !important;
  }

  &:disabled {
    border: 1px solid $main-input-border-clr !important;
    background-color: $main-input-background-clr;
    color: $main-input-clr;
    cursor: not-allowed;
  }
}

.button {
  background-color: transparent !important;
  border-color: transparent !important;
  color: $main-btn-clr !important;
  font-size: 18px;
  padding: 0;
  right: 2%;
  top: 50%;
  translate: 0 -50%;
  height: 40px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  > i {
    font-size: 24px;
  }
}

.file {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  cursor: pointer;
  opacity: 0;
  height: 48px;
}
