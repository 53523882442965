@import "../../styles/theme/app/variables.scss";

.closedState {
  background-color: #f9fafc;
  font-family: $main-dashboard-font;
  color: $main-font-clr;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 24px;
    background-color: white;
    border-top: 1px solid $main-bg-clr;
    border-bottom: 1px solid $main-bg-clr;
    position: relative;
    margin-bottom: 48px;
  }

  .container {
    border-radius: 10px;
    background-color: $main-bg-clr;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    margin: 24px;

    .infoCard {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 24px 0 24px 24px;
      max-width: 500px;

      .title {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        text-transform: capitalize;
        color: $dark-medium-font-clr;
        margin-bottom: 16px;
      }

      .desc {
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        text-transform: capitalize;
        color: $muted-clr;
        margin: 0 0 24px;
      }
    }

    .image {
      width: 100%;
      height: 100%;
    }
  }
}
