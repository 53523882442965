@import "../../../styles/theme/app/variables";

@keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}
@-webkit-keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}

.devicesBlocksHeader {
  font-weight: bolder;
  color: rgb(131, 131, 132);
  background-color: rgb(249, 249, 249);
  padding: 7px;
  font-size: 16px;
  margin-top: 9.5px;
  margin-bottom: 9.5px;
  line-height: 1.6;
  height: 40px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  animation: pulse 3s infinite ease-in-out;
  -webkit-animation: pulse 3s infinite ease-in-out;
}

.labelLoading {
  display: inline-block;
  width: 32px;
  height: 20px;
  margin-bottom: -4px;
  border-radius: 6px;
  animation: pulse 3s infinite ease-in-out;
  -webkit-animation: pulse 3s infinite ease-in-out;
}

.devicesBlocks {
  box-shadow: 0 3px 3px 0 rgba(92, 92, 92, 0.21) !important;
  border-radius: 12px;
  background-color: #fff;
  height: 112px;
}

.notext {
  color: white;
}
.summarybckground {
  background: #f3f3f3 !important ;

  padding-right: 40px;
  padding-left: 40px;
}
.number {
  padding-top: 27px;
  padding-left: 24px;
}
.testacqutop {
  padding-top: 10px;
}

.sumdiv {
  border-style: solid;
  border-width: 1px;
}

.noData {
  font-size: 22px;
}
.filterdiv {
  margin-bottom: 10px;
}
.todayddate {
  margin-right: 14px;
  margin-top: 6px;
  margin-bottom: 10px;
  font-size: 14px;
}
.today {
  font-weight: 700;
}
.pull-right {
  float: right !important;
  margin-right: 20px;
  margin-top: 3rem;
}
.barchartt {
  height: 70%;
  width: 60%;
}
.xyz {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 0px;
  color: $main-font-clr;
}
.xyzz {
  font-size: 20px;
  font-weight: bold;
  margin-left: 5px;
  margin-top: 10px;
  margin-bottom: 0px;
  color: #615f72;
}
.spantitle {
  font-weight: bold;
  font-size: 16px;
  margin-left: 24px;
  margin-top: 8px;
  margin-bottom: 18px;
  color: $main-font-clr;
}

.style-chart {
  font-weight: 700;
  font-size: 14px;
  color: $main-font-clr;
}
.summarytitle {
  font-size: 12px;
  margin-top: 10px;
  font-weight: 600;
  margin-bottom: 3px;
  color: gray;
}
@media (max-width: 1445px) {
  .summarytitle {
    font-size: 8px;
  }
}

.spantitle2 {
  font-size: 30px;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
  color: #808080;
}

.xyz2 {
  margin-top: 30px;
}
.newnumber {
  font-size: 14px;
  font-weight: 700;
  margin-left: 4px;
  color: $main-btn-clr;
  text-decoration: underline;
}
.badge {
  margin: 5px !important;
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 10px;
  color: #fff;
  vertical-align: middle;
  background-color: #17a1e5;
  border-radius: 10px;
}
.label {
  display: block;
  margin: 20px 0 5px 0;
}
.verticalLabel {
  margin: 10px 0;
}
.value {
  color: $main-font-clr;
  display: block;
  font-weight: bold;
}
.valueInlinedesktop {
  color: #1a70fb;
  font-weight: bold;
  font-size: 14px;
}
.valueInlineandroid {
  color: #33bcad;
  font-weight: bold;
}
.valueInlineios {
  color: #ed1b52;
  font-weight: bold;
}
.valueInline {
  font-size: 25px;
}
.pending {
  padding-left: 10%;
}
.alert {
  position: absolute;
  top: 20%;
  left: 40%;
  width: 30%;
  background-color: #013874 !important;
  color: #ffffff !important;
  z-index: 5;
  @media only screen and (max-width: 415px) {
    width: 100%;
    margin: auto;
    position: absolute;
    top: 20%;
    left: 0%;
  }
}
.pushcolr {
  font-size: 30px;
}
.bigElement {
  text-align: center;

  .value {
    font-size: 18px;
    color: $main-font-clr;
  }
  .label {
    margin: 0;
  }
  &.bigElementRed {
    .value {
      color: #ed1b52;
    }
  }
  &.bigElementBlue {
    .value {
      color: #1a70fb;
    }
  }
}
.sumnumbers {
  font-size: 16px !important;
  font-weight: 700;
  margin-left: 12px;
  text-align: center;
}
.sumicons {
  font-size: 14px;
  font-weight: 700;
  margin-left: 5px;
  color: #65aae6;
}
.sumbutton {
  font-size: 12px;
  font-weight: 200;
  margin-left: 15px;
  border-radius: 10px;
  padding: 2px 10px 2px;
  background-color: #1789ec;
}
.timebutton {
  border-radius: 10px !important;
  width: 40%;
  margin-left: 5px;
  margin-right: 10px;
  color: white !important ;
  padding: 2px 10px 2px;
  border: none !important;
  box-shadow: 0px 17px 10px -10px rgba(0, 0, 0, 0.35);
  font-size: 14px;
  font-weight: 200;
  outline: none;
}

.boostheader {
  font-size: 20px;
  font-weight: 550;
  margin-top: 20px;

  color: #808080;
}
.boostheadericon {
  margin-left: 20px;
  font-size: 20px;
  font-weight: 550;
  margin-top: 20px;

  color: #358bff;
}
.boosticon {
  color: #65aae6;
  font-size: 18px;
}
.summ {
  padding-top: 10px;
}
.sumpercent {
  font-size: 13px;
  color: #65aae6;
  text-align: center;
  font-weight: 700;
  margin-left: 5px;
}
.androidpoint {
  font-size: 14px;
  color: rgb(0, 196, 159);
  margin-right: 10px;
  margin-left: 10px;
}
.IOSpoint {
  font-size: 16px;
  color: #fcde00;
}
.totalpoint {
  font-size: 16px;
  color: #d2d2d2;
}

.day {
  background-color: #ffffff;
}
.touchdiv {
  margin-left: 5% !important;
}
.touchdiv2 {
  margin-left: 30%;
  margin-bottom: 20px;
  margin-top: 15px;
}
.acText {
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  padding-top: 20px;
  color: grey;
}
.acqdiv {
  margin-top: 20px;
  margin-left: 41px;
  font-size: 22px;
  font-weight: 700;
  color: #828080;
}
.sumanalyticsblock {
  background: #ffff !important;
  margin-bottom: 24px;
  border-radius: 4px;
  box-shadow: 0px 22px 42px 0px #f0eefa;
}
.NoData {
  font-size: 22px;
  text-align: center;
  padding-top: 30px;
  color: gray;
}
.nohits {
  font-weight: 600;
  font-size: 14px;
  padding-top: 30%;
  color: #545a5a;
  text-align: center;
}

// spinner
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: 0 auto;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loaderContainer {
  padding-top: 24px;
}

@media screen and (min-width: 768px) {
  .filterdiv,
  .todayddate {
    display: inline-block;
  }
  .filterdiv {
    margin-right: 20px;
  }
}

@media screen and (max-width: 1170px) and (min-width: 768px) {
  .bigElement {
    margin: 10px 0;
  }
}

@media screen and (max-width: 767px) {
  .summarybckground {
    padding-top: 25px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .filterdiv,
  .todayddate {
    float: none;
  }
  .calendarContainer {
    text-align: center;
  }

  .breadcrumbSty {
    margin-bottom: 0 !important;
  }
}

@media screen and (max-width: 480px) {
  .summarybckground {
    padding: 0 10px;
  }
}

/* New UI */

.summary-dashboard {
  background-color: #f9fafc;
  font-family: $main-dashboard-font;
  color: $main-font-clr;
  overflow: hidden;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 24px;
    background-color: white;
    position: relative;
    border-top: 1px solid $main-bg-clr;
    border-bottom: 1px solid $main-bg-clr;
    margin-bottom: 48px;

    .headerButtons {
      display: flex;
      align-items: center;
      gap: 16px;

      .createCampaignBtn {
        height: 48px;
        background-color: $main-btn-clr;
        color: white;
        font-family: $main-dashboard-font;
        padding: 0 14px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        gap: 6px;
      }

      .createCampaignBtn:focus:enabled,
      .createCampaignBtn:hover:enabled {
        background-color: $main-btn-clr-hvr;
      }

      .createCampaignBtn:disabled {
        background-color: $main-btn-clr-disabled;
        color: white;
        border: $main-btn-clr-disabled;
      }
      button {
        border: none;
        outline: none;
        border-radius: 5px;
        font-size: 15px;
        font-family: $main-dashboard-font;
        transition: color ease-in-out 150ms;
      }

      button:disabled {
        background-color: $main-btn-clr-disabled;
        color: white;
        border: $main-btn-clr-disabled;
      }
    }
  }

  .retailGainStatisticsContainer {
    padding: 11px 24px;
  }
}

.datepicker-container {
  padding-top: 5px;
  background-color: $main-btn-clr;
  font-size: 15px !important;
  border-radius: 6px;
  border-color: $main-btn-clr;

  &:hover,
  &:focus,
  &:active,
  &:visited {
    background: $main-btn-clr-hvr;
    border-color: $main-btn-clr-hvr;
  }
}

.datepicker-container button div:first-of-type span {
  margin-right: 5px;
}

.datepicker-container button div span {
  color: #1d86f1 !important;
}
@media (max-width: 786px) {
  .header {
    flex-direction: column;
    .headerButtons {
      flex-direction: column;
    }
  }
}

.skeleton {
  border-radius: 4px;
  background-color: #fff;
  padding: 0 24px;
  margin-bottom: 24px;
}
