.whatsappButton {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    text-decoration: none;
    color: #fff;
  }

  &:active {
    color: #fff;
  }

  &:focus {
    color: #fff;
  }
}
