@import "../../styles/theme/app/variables";

.noBackground {
  background: none;
  border: 1px solid #e5e5e5;
  color: #353535;

  h6,
  h5,
  h4,
  h3,
  h2,
  h1 {
    color: white;
  }

  p {
    color: #353535;
  }
}

.notRounded {
  border-radius: 0;
}
