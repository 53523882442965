.create-event-modal-loader {
  position: absolute;
  top: 45%;
  left: 50%;
  z-index: 1000;
  transform: translate(-50%, -50%);
  i {
    font-size: 4rem;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
  }
}

.create-event-modal-header {
  display: flex;
  padding: 15px !important;
  font-size: 2rem;
  font-weight: bold;
  & > i {
    padding-top: 3px;
    margin-left: auto;
    cursor: pointer;
  }
}

.create-event-btns-container {
  display: flex;
  flex-direction: row-reverse;
  & > button:not(:first-of-type) {
    margin-left: auto;
    padding: 8px 15px !important;
    color: #fff !important;
    background-color: #1d86f1;
    font-size: 18px !important;
    border-radius: 4px;

    &:hover,
    &:focus,
    &:active,
    &:visited {
      color: #fff !important;
      background: #1d86f1;
    }
  }
}

.lang-container {
  display: flex;
  & > div:first-of-type {
    width: 17px !important;
    height: 17px !important;
    input {
      width: 17px !important;
      height: 17px !important;
      margin-right: 0.5rem !important;
      cursor: pointer !important;
    }
  }

  & > div {
    align-self: center;
  }
  & > div:nth-of-type(2) {
    margin-left: 1rem;
    font-size: 1.5rem;
    padding-top: 8px;
  }
}

.dialog-classname {
  width: 75%;
}

.create-event-form-container {
  & > div {
    margin-top: 0.5rem !important;
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
