@import "../../../../styles/theme/app/variables";
.button {
  width: 100px;
  float: right;
}
.saveButton {
  width: 100px;
  float: right;
}
.alert {
  position: absolute;
  top: 0%;
  background-color: #013874 !important;
  top: 40%;
  left: 30%;
  width: 30%;
  color: #ffffff !important;
  z-index: 5;
  @media only screen and (max-width: 415px) {
    width: 100%;
    margin: auto;
    position: absolute;
    top: 40%;
    left: 0%;
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  height: auto;
  color: white;
  z-index: 1000;
  transform: translate(-50%, -50%);
  i {
    font-size: 4rem;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
  }
}
.loader-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  background: #000;
  opacity: 0.5;
  left: 0px;
  z-index: 9999;
}

.loading-text-replacer-block {
  width: 100%;
  height: 65vh;
  background-color: #f7f7f7;
}

.loading-text {
  height: 20px;
  width: 60%;
  animation: pulse 3s infinite ease-in-out;
  -webkit-animation: pulse 3s infinite ease-in-out;
  position: relative;
  top: 20px;
  margin-top: 15px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.loading-text:first-child {
  margin-top: 0px;
}
.loading-text:nth-child(2n) {
  width: 30%;
}
.loading-text:nth-child(3n) {
  width: 90%;
}
.loading-text:nth-child(4n) {
  width: 40%;
}
@keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}
@-webkit-keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}
