@import "../../../styles/theme/app/variables.scss";

.textBox {
}

.label {
  color: $dark-font-clr;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.input {
  height: $main-input-height;
  color: $main-input-clr;
  border: 1px solid $main-input-border-clr;
  border-radius: $main-input-border-radius;
  padding: $main-input-padding;
  font-size: $main-input-font-size;
  font-weight: $main-input-font-weight;
  transition: all $main-input-transition-duration
    $main-input-transition-timing-function;
  width: 100%;
  background-color: $main-input-background-clr;
  font-size: $main-input-font-size;
  font-weight: $main-input-font-weight;
  outline: none;

  &:focus {
    border: 1px solid $main-input-border-clr-focus;
    background-color: $main-input-background-clr-focus;
    color: $main-input-clr-focus;
  }
  &:hover {
    border: 1px solid $main-input-border-clr-hvr;
  }
  &:disabled {
    background-color: $main-input-background-clr;
    color: $main-input-clr;
    border: 1px solid $main-input-border-clr;
  }
  &::placeholder {
    color: $main-input-placeholder-clr;
    font-size: $main-input-placeholder-font-size;
    font-weight: $main-input-placeholder-font-weight;
  }
}

.inputContainer {
  position: relative;

  .emojiContainer {
    position: absolute;
    top: 5px;
    right: 5px;

    > div:first-of-type {
      position: unset;
    }
  }
}

.right-input-hint {
  color: $main-clr !important;
  position: absolute;
  right: 3%;
  font-size: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
}

.right-clickable-input-hint {
  color: $main-clr !important;
  position: absolute;
  cursor: pointer;
  right: 5%;
  font-size: 1.5rem;
  top: 50%;
  z-index: 100;
  transform: translateY(-50%);
}

.input-right-clickable-hint {
  padding-right: 165px;
}

.maxRecommended {
  position: absolute;
  font-size: 1.4rem;
  top: 112%;
  right: 2px;
}

.maxRecommendedReached {
  color: #dc3545;
}

.hint {
  margin-top: 8px;
  font-size: 14px;
  color: #9a9a9a;
  font-weight: bold;

  > span {
    color: #9a9a9a;
  }
}

.error {
  font-size: 13px;
  color: $error-text-clr;
}
