@import "../../styles/theme/app/variables";
.panel {
  border-radius: 6px;

  > div:first-of-type {
    padding: 0;
  }
}

.header,
.footer {
  padding: 12px;
  background-color: #f2f2f2;
}

.header {
  margin-bottom: 12px;
}

.footer {
  margin-top: 12px;
}

.content {
  padding: 24px;
}
