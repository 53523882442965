.loadingCard {
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;

  .header {
    padding: 20px 24px;

    > span {
      display: inline-block;
      min-height: 24px;
      width: 100%;
      border-radius: 8px;
      animation: pulse 3s infinite ease-in-out;
      -webkit-animation: pulse 3s infinite ease-in-out;
    }
  }

  .body {
    background-color: #ffffff;
    padding: 16px 24px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 14px;

    > span {
      border-radius: 8px;
      min-height: 98px;
      min-width: 66px;
      flex: 1;
      animation: pulse 3s infinite ease-in-out;
      -webkit-animation: pulse 3s infinite ease-in-out;
    }
  }

  .footer {
    padding: 16px 24px;

    > span {
      border-radius: 8px;
      display: inline-block;
      min-height: 24px;
      width: 100%;
      animation: pulse 3s infinite ease-in-out;
      -webkit-animation: pulse 3s infinite ease-in-out;
    }
  }
}

@keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}

@-webkit-keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}
