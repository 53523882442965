@import "../../../../styles/theme/app/variables";

.createAppPush {
  background-color: #f9fafc;
  font-family: $main-dashboard-font;
  color: $main-font-clr;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 24px;
    background-color: white;
    border-top: 1px solid $main-bg-clr;
    border-bottom: 1px solid $main-bg-clr;
    position: relative;
    margin-bottom: 48px;
  }

  .content {
    padding: 0 24px;
  }

  .wizardButtonsContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    margin: 18px 0;
  }
}

.gridOneColumn {
  display: grid;
  grid-template-columns: 1fr;
}

.gridTwoColumn {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}

.apppush-content {
  background-color: #fff;
  padding: 24px;
  display: flex;
  border-radius: 8px;

  & > div:nth-of-type(1) {
    flex-basis: 60%;
  }
  & > div:nth-of-type(2) {
    flex-basis: 40%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
}

// send to

.sendToContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 12px;

  & > div:nth-of-type(1) {
    grid-column: 1 / span 2;
  }

  & > div:nth-of-type(2) {
    grid-column: 3 / span 3;
  }
}

.dateRangeContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}

.startOnPickerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin: 12px 0;
}

.datePicker {
  > div {
    > input {
      height: 49px;
    }
  }
}

.endOnPickerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin: 12px 0;
}

.timezonePickerContainer {
  margin: 12px 0;
}

.sendEveryContainer {
  margin: 12px 0;
}

.buttonsContainer {
  margin: 1.5rem 0;

  .buttonContainer {
    padding: 1rem;
    border-radius: 4px;
    border: 1px solid #eee;
    position: relative;
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 18px;

    .buttonNumber {
      position: absolute;
      top: 5px;
      right: 20px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      color: $main-font-clr;
      font-weight: 500;

      &.rtl {
        right: unset;
        left: 20px;
      }
    }

    .deleteButton {
      position: absolute;
      right: -16px;
      top: -16px;

      &.rtl {
        right: unset;
        left: -16px;
      }
    }
  }
}
