@import "../../styles/theme/app/variables.scss";

.multiValueInputContainer {
  .domainsContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    padding: 8px;

    .domain {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px;
      border-radius: 4px;
      background-color: #f5f5f5;
      font-size: 14px;
      font-weight: 500;
      color: #333333;

      .domainName {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .removeDomain {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: transparent;
        cursor: pointer;
      }
    }
  }

  input {
    width: 300px;
    padding: 8px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    outline: none;
    height: 48px;
  }
}
