@import "../../../styles/theme/app/variables";

.row {
  width: 100px;
}

.btn {
  background: transparent;
  position: absolute;
  width: 175px;
  height: 175px;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.btn {
  color: white;
  font-size: 16px;
}

.input {
  margin: 10px;
  padding: 5px;
  border-color: #6db3fd;
  border-radius: 5px;
}

.afterSelection {
  margin: 10px;
}
.butn {
  color: white;
  background: #6db3fd;
  margin: 5px;
  border: none;
  border-radius: 5px;
}
.butnHide {
  color: white;
  background: #c83517;
  margin: 5px;
  border: none;
  border-radius: 5px;
}
.coln {
  padding: 5px;
}

.message {
  margin-top: 2px;
  margin-left: 12px;
}

.scroller {
  margin: 10px;
  height: 400px;
  width: 590px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
}
