.sidebar {
  overflow-y: auto;
  height: calc(100vh - 48px);
  position: fixed;
  top: 0;

  // scrollbar
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.sidebarTopContainer {
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 24px;
}

.sidebarTopContainerInner {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 24px;

  > img {
    cursor: pointer;
  }

  .projectName {
    color: #fff;
    font-size: 18px;
    text-align: center;
    font-weight: 400;
  }
}

.placeholderDiv {
  height: 86px;
}

.sidebarItemLabel {
  color: #f5f5f5;
  font-size: 16px;
  font-weight: 400;

  .comingSoon {
    color: #00ed64;
    font-size: 12px;
    font-weight: 700;
    margin-left: 8px;
  }
}

.sidebarSubItemLabel {
  color: #f5f5f5;
  font-size: 14px;
  font-weight: 400;
}

.sidebarItemIcon {
  width: 24px;
  height: 24px;
  font-size: 24px;
  flex-shrink: 0;
  color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
}
