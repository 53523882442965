@import "../../styles/theme/app/variables.scss";

.adAudience {
}

.modalDialog {
  background-color: #fff;
  width: 80% !important;
  max-width: 650px;
  border-radius: 5px;
  font-family: $main-dashboard-font;
  color: $main-font-clr;
  max-height: 80vh;
  overflow: auto;

  button {
    border: none;
    outline: none;
    font-size: 15px;
    font-family: $main-dashboard-font;
    transition: color ease-in-out 150ms;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    height: 42px;
    padding: 0 14px;
  }

  button:disabled {
    background-color: $main-btn-clr-disabled;
    color: white;
    border: $main-btn-clr-disabled;
  }

  .platforms {
    .platformBanner {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 15px;
      border: 1px solid #76767619;
      border-radius: 5px;
      margin: 15px 0;
      justify-content: space-between;
      position: relative;

      .comingSoon {
        position: absolute;
        inset: 0;
        background-color: #00000073;
        border-radius: 5px;
        display: grid;
        place-content: center;

        > p {
          color: white;
          font-weight: bolder;
          font-size: 30px;
        }
      }

      .logo {
        > img {
          width: 40px;
        }
      }

      .info {
        display: flex;
        flex-direction: column;

        > p:first-child {
          font-size: 15px;
          margin-bottom: 3px;
          font-weight: bold;
        }

        > p:last-child {
          font-size: 14px;
          margin-bottom: 5px;
        }
      }

      .loginBtn {
        .zapier {
          width: 200px;
          height: 42px;
          background-color: #ff4f00;
          color: white;
        }
      }

      .fbConnected {
        width: 200px;
        height: 42px;
        background-color: #3b5998;
        color: white;
        display: grid;
        place-content: center;
        font-weight: bold;
      }
    }
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: flex-end;
    padding: 15px;

    .cancelBtn {
      color: $main-btn-clr;
      border: 1px solid $main-btn-clr;
      background-color: white;
      border-radius: 5px;
    }

    .cancelBtn:focus:enabled,
    .cancelBtn:hover:enabled {
      color: white;
      background-color: $main-btn-clr;
    }

    .confirmBtn {
      color: white;
      background-color: $main-btn-clr;
      border: 1px solid $main-btn-clr;
      border-radius: 5px;
    }

    .confirmBtn:focus:enabled,
    .confirmBtn:hover:enabled {
      background-color: $main-btn-clr-hvr;
    }
  }

  .inputRow {
    margin: 25px 0;

    .input {
      border: 1px solid #1f2f3f;
      font-family: inherit;
      font-size: 16px;
      font-weight: normal;
      height: 48px;
      background-color: white;
      border-radius: 5px;
      padding: 0 8px;
      width: 100%;
    }

    .input:focus,
    .input:focus-within,
    .input:active {
      border: 1px solid #033670;
      color: #033670;
    }

    > p:first-child {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 5px;
    }
  }

  .sectionNamePanel {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: center;
    margin-top: 25px;
    border-bottom: 1px solid #ddd;

    > button {
      outline: none;
      border: none;
      background-color: transparent;
      font-size: 15px;
      font-weight: normal;
      width: 100%;
      padding-bottom: 25px;
    }

    .selectedSection {
      border-bottom: 1px solid $main-btn-clr;
      color: $main-btn-clr;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .modalHeader {
    border-bottom: 1px solid #eee;
    color: $main-font-clr;

    > button {
      color: $main-font-clr;
      font-size: 25px;
    }

    .modalTitle {
      font-weight: bold;
      font-size: 16px;
      color: $main-font-clr;
      padding: 15px;
    }
  }

  .modalBody {
    padding: 15px;
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  background: #000;
  opacity: 0.5;
  left: 0px;
  z-index: 9999;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  height: auto;
  color: white;
  z-index: 1000;
  transform: translate(-50%, -50%);
  i {
    font-size: 4rem;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
  }
}
