@import "../.././../../styles/theme/app/variables.scss";

.welcomeStep {
  display: flex;
  align-items: stretch;
  flex-direction: column;

  .welcomeStep__image {
    margin: 0 auto;
    margin-bottom: 16px;
  }

  .welcomeStep__title,
  .welcomeStep__description {
    color: $dark-medium-font-clr;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    text-transform: capitalize;
  }

  .welcomeStep__description {
    margin-bottom: 16px;
  }

  .welcomeStep__video {
    height: 330px;
    max-height: 690px;
    border-radius: 8px;
  }

  .welcomeStep__buttonsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 36px;
  }
}
