@import "../../../styles/theme/app/variables";

.addModal {
  width: 905px;
  height: 420px;
  border-radius: 20px;
  :global {
    .modal-content {
      height: 100%;
      height: 420px;
      margin-top: 67px !important;
      border-radius: 20px;
      background-color: #ffffff;
      .modal-body {
        height: 420px;
        .mainContent {
          height: 420px;
          padding: 0%;
        }
      }
    }
  }
}
.slide {
  width: 100%;
  height: 100%;
  background: transparent;
  height: 390px;
  position: relative;
}
.skip {
  width: 30px;
  height: 18px;
  font-family: $main-dashboard-font;
  font-size: 16px;
  font-weight: 500;
  line-height: 2;
  text-align: left;
  color: #393939;
  cursor: pointer;
  text-decoration: underline;
  float: left;
  position: absolute;
  bottom: 25px;
  z-index: 1000000000;
}
.next {
  width: 150px;
  height: 42px;
  color: #ffffff;
  cursor: pointer;
  float: right;
  font-size: 16px;
  font-weight: 500;
  border-radius: 43px;
  background-image: linear-gradient(71deg, #024da1, #ed1650);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.34);
  position: absolute;
  bottom: 10px;
  left: 82%;
  z-index: 100000000;
}

.parse {
  width: 155px;
  height: 142px;
  object-fit: contain;
}
.group {
  width: 200.3px;
  height: 220.4px;
  object-fit: contain;
}
.groupcenter {
  width: 78px;
  height: 73px;
  object-fit: contain;
  padding-top: 10px;
  padding-left: 18px;
}
.firstgroupRow {
  margin-bottom: 5px;
}
.slideback {
  background-color: #ffeef3;
}
.bulidmobileappsfaster {
  margin-top: 30px;
  margin-bottom: 20px;
  height: 30px;
  width: 700px;
  font-family: $main-dashboard-font;
  font-size: 23px;
  font-weight: 600;
  line-height: 1.15;
  text-align: center;
  margin-inline-start: 100px;
  color: #024da1;
}
.bodycontant {
  //width: 573px;
  height: 61px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  color: #393939;
}
.secondslidericon {
  width: 296.7px;
  height: 180px;
  object-fit: contain;
}
.secondslidesmallicon {
  width: 116px;
  height: 90.6px;
  object-fit: contain;
  margin-top: 45px;
  padding-left: 32px;
}
.retain {
  height: 30px;
  object-fit: contain;
  font-family: $main-dashboard-font;
  font-size: 26px;
  font-weight: 600;
  line-height: 1.15;
  text-align: center;
  color: #024da1;
  margin-left: auto;
  margin-right: auto;
}
.secondSlideContant {
  width: 630px;
  height: 61px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  color: #393939;
  margin-left: auto;
  margin-right: auto;
}
.maskicon {
  width: 330px;
  height: 200px;
  object-fit: contain;
}
.splashicon {
  width: 376.3px;
  height: 180px;
  margin: auto;
  object-fit: contain;
}
.createproject {
  width: 320px;
  height: 54px;
  border-radius: 43px;
  background-image: linear-gradient(78deg, #024da1, #ed1650);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.34);
  color: #ffffff;
  margin-left: 280px;
  font-size: 20px;
  font-weight: 500;
}
.imgico {
  width: 296.7px;
  height: 230px;
  object-fit: contain;
}
.sdltext {
  height: 20px;
  font-size: 16px;
  font-family: $main-dashboard-font;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.18;
  letter-spacing: normal;
  padding-left: 35px;
  margin-top: 15px;
  color: #424143;
}
