@import "../../../styles/theme/app/variables.scss";

.inputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.inputContainer label {
  color: $dark-font-clr;
  font-size: $main-font-size;
  margin-bottom: 6px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 4px;

  .required {
    color: $error-text-clr;
  }
}

.inputContainer input {
  border: $main-input-border;
  border-color: $main-input-border-clr;
  border-radius: $main-input-border-radius;
  padding: $main-input-padding;
  font-size: $main-input-font-size;
  font-weight: $main-input-font-weight;
  color: $main-input-clr;
  background-color: $main-input-background-clr;
  transition:
    border-color $main-input-transition-duration
      $main-input-transition-timing-function,
    box-shadow $main-input-transition-duration
      $main-input-transition-timing-function;
  height: $main-input-height;
  outline: none;

  &:focus {
    border-color: $main-input-border-clr-focus;
    box-shadow: 0 0 0 0.2rem rgba($main-input-clr-focus, 0.25);
    background-color: $main-input-background-clr-focus;
  }

  &::placeholder {
    color: $main-input-placeholder-clr;
    font-size: $main-input-placeholder-font-size;
    font-weight: $main-input-placeholder-font-weight;
  }

  &:hover:not(:disabled) {
    border-color: $main-input-border-clr-hvr;
  }
}

.inputContainer input[type="number"]::-webkit-inner-spin-button,
.inputContainer input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inputContainer input[type="number"] {
  -moz-appearance: textfield;
}

.inputContainer input[type="number"]:hover,
.inputContainer input[type="number"]:focus {
  outline: none;
}

.inputContainer input[type="number"]::-webkit-inner-spin-button:hover,
.inputContainer input[type="number"]::-webkit-outer-spin-button:hover {
  background-color: #eee;
}

.inputContainer input[type="number"]::-webkit-inner-spin-button:active,
.inputContainer input[type="number"]::-webkit-outer-spin-button:active {
  background-color: #ccc;
}

.inputContainer .error {
  color: $error-text-clr;
  font-size: $secondary-font-size;
  margin-top: 6px;
}
