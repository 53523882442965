@import "../../styles/theme/app/variables.scss";

.div-filter-category-large {
  top: -4.2rem !important;
  background-color: green;
}

.div-filter-category {
  position: absolute;
  z-index: 1000;
  background-color: #fff;
  min-width: 170px;
  border-radius: 5px;
  border: 1px solid #70707026;
  overflow-y: auto;
  top: 45px !important;
  right: 5px;
  padding: 5px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  width: 100%;

  & > p {
    padding: 8px;
    font-size: 13px;
    cursor: pointer;
    font-weight: normal;
    color: $main-font-clr;
    transition: color 150ms ease-in-out;
    margin: 0;
    &:hover {
      color: #000;
      font-weight: bold;
    }
  }
}

.hover-danger:hover {
  color: #dc3545 !important;
}

@keyframes datatable_menu {
  0% {
    opacity: 0;
    right: 0%;
  }
  100% {
    opacity: 1;
    right: 60%;
  }
}
