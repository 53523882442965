@import "../../../../styles/theme/app/variables";

.mainContainer {
  display: flex;
  gap: 12px;
  width: 100%;
}

.formContainer {
  flex: 3;
}

.devicePreviewContainer {
  flex: 1;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  width: 100%;
}

.container {
  background-color: #f9fafc;
  font-family: $main-dashboard-font;
  color: $main-font-clr;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 24px;
    background-color: white;
    border-top: 1px solid $main-bg-clr;
    border-bottom: 1px solid $main-bg-clr;
    position: relative;
    margin-bottom: 48px;

    .headerButtons {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }

  .form {
    margin: 24px 32px;
  }

  .TextAreaField {
    margin-bottom: 0px;
    max-height: 150px;
  }
}

.formField {
  margin-bottom: 24px;

  .label {
    color: $dark-font-clr;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .input {
    height: $main-input-height;
    color: $main-input-clr;
    border: 1px solid $main-input-border-clr;
    border-radius: $main-input-border-radius;
    padding: $main-input-padding;
    font-size: $main-input-font-size;
    font-weight: $main-input-font-weight;
    transition: all $main-input-transition-duration
      $main-input-transition-timing-function;
    width: 100%;
    background-color: $main-input-background-clr;
    font-size: $main-input-font-size;
    font-weight: $main-input-font-weight;
    outline: none;

    &:focus {
      border: 1px solid $main-input-border-clr-focus;
      background-color: $main-input-background-clr-focus;
      color: $main-input-clr-focus;
    }

    &:hover {
      border: 1px solid $main-input-border-clr-hvr;
    }

    &:disabled {
      background-color: $main-input-background-clr;
      color: $main-input-clr;
      border: 1px solid $main-input-border-clr;
    }

    &::placeholder {
      color: $main-input-placeholder-clr;
      font-size: $main-input-placeholder-font-size;
      font-weight: $main-input-placeholder-font-weight;
    }
  }

  .textArea {
    color: $main-input-clr;
    border: 1px solid $main-input-border-clr;
    border-radius: $main-input-border-radius;
    padding: $main-input-padding;
    font-size: $main-input-font-size;
    font-weight: $main-input-font-weight;
    transition:
      color $main-input-transition-duration
        $main-input-transition-timing-function,
      background-color $main-input-transition-duration
        $main-input-transition-timing-function,
      border $main-input-transition-duration
        $main-input-transition-timing-function;
    width: 100%;
    background-color: $main-input-background-clr;
    font-size: $main-input-font-size;
    font-weight: $main-input-font-weight;
    outline: none;

    &:focus {
      border: 1px solid $main-input-border-clr-focus;
      background-color: $main-input-background-clr-focus;
      color: $main-input-clr-focus;
    }

    &:hover {
      border: 1px solid $main-input-border-clr-hvr;
    }

    &:disabled {
      background-color: $main-input-background-clr;
      color: $main-input-clr;
      border: 1px solid $main-input-border-clr;
    }

    &::placeholder {
      color: $main-input-placeholder-clr;
      font-size: $main-input-placeholder-font-size;
      font-weight: $main-input-placeholder-font-weight;
    }
  }

  .error {
    color: $error-text-clr;
  }

  .warning {
    color: $warning-text-clr;
  }

  .fileInput {
    display: none;
  }

  // represents the file input
  .fileInputLabel {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    height: $main-input-height;
    border: 1px solid $main-input-border-clr;
    border-radius: $main-input-border-radius;
    padding: $main-input-padding;
    font-size: $main-input-font-size;
    font-weight: $main-input-font-weight;
    background-color: $main-input-background-clr;
    cursor: pointer;

    &:hover {
      border: 1px solid $main-input-border-clr-hvr;
    }

    &:disabled {
      background-color: $main-input-background-clr;
      color: $main-input-clr;
      border: 1px solid $main-input-border-clr;
    }

    .uploadIcon {
      margin-right: 8px;
      margin-left: 8px;
    }

    .uploadText {
      color: $main-input-clr;
      font-size: $main-input-font-size;
      font-weight: $main-input-font-weight;
      margin-right: 8px;
      margin-left: 8px;

      // ellipsis
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .imagePreviewWrapper {
    position: relative;
    display: inline-block;
    margin-top: 10px;
    margin-right: 10px;
  }

  .imagePreview {
    width: 100px;
    height: 100px;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
    object-fit: cover;
  }

  .removeImageIcon {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: red;
    color: white;
    border-radius: 50%;
    cursor: pointer;
    padding: 2px 5px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.repeatContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 12px;
}
.checkboxContainer {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-weight: bold;
}

.checkbox {
  margin-right: 10px;
  width: 18px !important;
  height: 18px !important;
  border: 1px solid #ccc !important;
  border-radius: 3px !important;
  outline: none !important;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  cursor: pointer;
  position: relative;
  display: inline-block;
}

.checkbox:checked::before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  background-color: #007bff;
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 2px;
}

.checkbox:focus {
  border-color: #007bff;
}

.buttonsContainer {
  width: 96%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem auto;
  padding: 2rem;
}
