@import "../../../styles/theme/app/variables";

.title {
  text-transform: uppercase;
  font-size: 12px;
}
.contain {
  position: relative;
  padding-right: 50px;
  margin-bottom: 20px;

  .input {
    height: 48px;
    border: 1px solid $main-input-border-clr;
    border-radius: $main-input-border-radius;
    color: $main-input-clr;
    font-size: $main-input-font-size;
    background-color: $main-input-background-clr;
    padding: 0 10px;
    width: 100%;
    cursor: pointer;
    outline: none;
  }

  .colorButton {
    position: absolute;
    top: 0;
    right: 0;
    width: 48px;
    height: 48px;
    border: 1px solid $main-input-border-clr;
    border-radius: $main-input-border-radius;
    cursor: pointer;
  }

  .popOver {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 100;
    width: 100%;

    > div:first-child {
      width: 100% !important;
    }
  }
}
