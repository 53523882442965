.tabsRoot {
  ul > li > a {
    &:focus {
      outline: none;
    }
  }
  ul > li.active > a {
    &:hover,
    &:focus {
      background-color: #5c8a36;
    }
    background-color: #2c97de !important;
  }
}
