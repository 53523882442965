@import "../../../styles/theme/app/variables";

.contain {
  position: absolute;
  top: 5px;
  right: 15px;
  .button {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 25px;
    cursor: pointer;
    z-index: 1;
  }

  .popOver {
    position: absolute;
    top: 35px;
    right: 0;
    z-index: 100;
  }
}

.containdown {
  z-index: 1;
  position: relative;
  .button {
    font-size: 25px;
    cursor: pointer;
    z-index: 1;
  }
  .popOver {
    position: absolute;
    z-index: 100;
    left: 50%;
    transform: translateX(-50%);
  }
}
