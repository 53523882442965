@import "../../../../styles/theme/app/variables";

.createAppPush {
  background-color: #f9fafc;
  font-family: $main-dashboard-font;
  color: $main-font-clr;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 24px;
    background-color: white;
    border-top: 1px solid $main-bg-clr;
    border-bottom: 1px solid $main-bg-clr;
    position: relative;
    margin-bottom: 48px;
  }

  .content {
    padding: 0 24px;
  }

  .createAppPushBtnsContainer {
    padding: 24px;
    display: flex;
    flex-direction: row-reverse;
    margin-top: 1rem;
    & > button {
      padding: 0.8rem 2rem !important;
    }
  }
}

.imgSizeError {
  color: $error-text-clr;
  position: relative;
  text-align: center;
  top: 20px;
}

.checkbox-container {
  display: flex;
  align-items: center;
  > input {
    margin: 0;
    transform: scale(1.2);
  }

  .checkbox-label {
    color: #5c5c5c;
    font-size: 1.6rem;
    margin: 0;
    margin-left: 0.7rem;
    font-weight: bold;
  }
}

.gifs-list-container {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-height: 400px;
  overflow: auto;
  & > div {
    margin-bottom: 10px;
    flex-basis: 32%;
    height: 200px;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.2);
    & > img {
      height: 100%;
      width: 100%;
      border-radius: 5px;
    }
  }
}

.active-gif img {
  border: 5px solid $main-clr;
}

.apppush-content {
  background-color: #fff;
  padding: 2rem !important;
  display: flex;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  & > div:nth-of-type(1) {
    flex-basis: 60%;
  }
  & > div:nth-of-type(2) {
    flex-basis: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.campaign-name-input {
  // margin-top: 0 !important;
  // margin-bottom: 0 !important;
  // input {
  //   border-color: #1d86f1 !important;
  // }
  // & > label {
  //   color: #5c5c5c !important;
  //   font-weight: bold !important;
  //   font-size: 1.6rem !important;
  // }
}

.message-form-input-cont {
  & > div:nth-of-type(2) {
    top: 6rem !important;
    right: 0.5rem !important;
    & > div {
      z-index: 1000 !important;
    }
  }
}

.message-youtube-cont > div {
  margin-top: 1.4rem !important;
}

.message-form-input {
  // label {
  //   margin-bottom: 1rem;
  //   color: #5c5c5c !important;
  //   font-weight: 700 !important;
  //   font-size: 1.6rem !important;
  // }
  // textarea,
  // input {
  //   font-size: 1.5rem !important;
  //   resize: none !important;
  //   border-color: #1d86f1 !important;
  // }
}
.my-form-label {
  margin-bottom: 1rem;
  color: #5c5c5c !important;
  font-weight: 700 !important;
  font-size: 1.6rem !important;
}

.video-input-container {
  & > div > div > span > input:first-of-type {
    border-color: $main-clr !important;
  }
  // label {
  //   margin-top: 1.4rem;
  //   color: #5c5c5c !important;
  //   font-weight: 700 !important;
  //   font-size: 1.6rem !important;
  // }
}

.select-style {
  & > div {
    // z-index: 12;
    border-color: $main-clr !important;
    min-height: 5rem;
    // max-height: 15rem;
    height: auto;
    // z-index: 10;
    overflow: auto;
    & > div > div > span {
      line-height: 46px !important;
      font-size: 1.5rem !important;
    }
    & > span > div > input {
      display: hidden;
    }
  }
  // & > div > span:nth-of-type(1) {
  //   display: none;
  // }
}

.apppush-notification-icon {
  position: relative;
  margin-top: 0.4rem;

  label {
    color: #5c5c5c !important;
    font-weight: bold !important;
    font-size: 1.6rem !important;
  }
  .notification-icon {
    position: absolute;
    top: 5rem;
    right: 2rem;
    font-size: 26px;
    z-index: 100;
  }
  & > div > div > span {
    & > input:last-of-type,
    & > input:first-of-type {
      border-color: $main-clr !important;
    }
  }
}

.orientation {
  margin-top: 0 !important;
}

.publish-fields {
  & > div > div {
    margin-top: 0 !important;
  }
}
.optional-image-url {
  input,
  button {
    border-color: $main-clr !important;
  }
}

.optional-image-url-input {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.container-radios {
  margin-top: 2rem;
  width: 100%;
  border-radius: 5px;
  background-color: #f8f8f8;
  padding: 2rem;
}

.appPushType {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  cursor: pointer;
}

.chkboxOption {
  font-size: 16px;
}

.inputGroup {
  background-color: #f2f3f7;
  display: block;
  margin: 10px 0;
  position: relative;

  label {
    padding: 12px 30px;
    width: 100%;
    display: block;
    text-align: left;
    color: #3c454c;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: color 200ms ease-in;
    overflow: hidden;

    &:before {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      content: "";
      background-color: $main-clr;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale3d(1, 1, 1);
      transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
      opacity: 0;
      z-index: -1;
    }

    &:after {
      width: 32px;
      height: 32px;
      content: "";
      border: 2px solid #d1d7dc;
      background-color: #fff;
      background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: 2px 3px;
      border-radius: 50%;
      z-index: 2;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      transition: all 200ms ease-in;
    }
  }

  input:checked ~ label {
    color: #fff;

    &:before {
      transform: translate(-50%, -50%) scale3d(56, 56, 1);
      opacity: 1;
    }

    &:after {
      background-color: #ed1650;
      border-color: #ed1650;
    }
  }

  input {
    width: 32px;
    height: 32px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
  }
}

code {
  background-color: #9aa3ac;
  padding: 0 8px;
}

.radio-container {
  background-color: #f2f3f7;
  border-radius: 10px;
}

.input-control {
  opacity: 0;
  margin-top: 10px;
  transition: opacity 0.5s linear;
}

// .options-textbox-container {
//   & > div {
//     margin-top: 1rem;
//     margin-bottom: 0rem;
//     input {
//       height: 5rem !important;
//       font-size: 1.5rem !important;
//       border-color: $main-clr !important;
//     }
//     button {
//       height: 5rem !important;
//       border-color: $main-clr !important;
//     }
//   }
// }

.datepicker-container {
  & > div {
    margin-top: 1rem;
    margin-bottom: 2rem;
    input {
      height: 5rem;
      font-size: 1.5rem;
      border-color: $main-clr;
    }
  }
}

.modified-datepicker-container {
  & > div {
    margin-top: 0rem;
  }
}

.time-zone-picker {
  input {
    height: 5rem;
    font-size: 1.5rem;
    border-color: $main-clr;
  }
}

.errorAlert {
  transition: opacity 1.5s linear;
  color: $error-text-clr;
  text-align: center;
  opacity: 0;
}

.gif-input-form-container {
  display: flex;
  & > div:nth-of-type(1) {
    flex-basis: 45%;
  }
  & > div:nth-of-type(2) {
    flex-basis: 5%;
    font-weight: bold;
    vertical-align: middle;
    text-align: center;
    margin-top: 5.5rem;
  }
  & > div:nth-of-type(3) {
    flex-basis: 50%;
  }
}

.transform-in {
  transition: opacity 0.5s ease-in;
  opacity: 1;
}

.advanced-contain {
  width: 70%;
}

.portraitdropdown {
  margin-top: 23px !important;
  margin-bottom: 28px !important;
}

.portraitdropdownSty2 {
  margin-bottom: 5px !important;
}

.hintSty {
  text-align: right;
  color: #a2a09e;
  margin-bottom: 0 !important;
}

.span-label {
  font-size: 16px;
  margin-left: 10px;
}

.radion-container {
  padding: 10px 10px;
  cursor: pointer;
  margin: 10px 0;
  background-color: #e2e2e2;
  border: 3px solid #e2e2e2;
  border-radius: 5px;
  text-align: center;
  img {
    overflow: hidden;
    height: 100%;
    width: 100%;
  }
}

.hightlight-checked {
  border-radius: 5px;
  border: 3px solid #5c5c5c;
}

.orientation-label {
  margin-bottom: -17px;
  font-weight: bold;
}

.localization-button {
  border-radius: 8px !important;
  width: 18%;
  margin-right: 10px;
  color: #fff !important;
  border: none !important;
  font-size: 14px;
  outline: none;
  background-color: rgb(128, 128, 128);
  padding: 3px 0;
  cursor: pointer;
}

.localization-active {
  background-color: $main-clr;
}

.display_none {
  display: none;
}

.gifUrlTitle {
  padding: 0;
  margin: 0;
  margin-left: 10px;
  font-weight: bold;
}

.cancelButton {
  color: black !important;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  background: #000;
  opacity: 0.5;
  left: 0px;
  z-index: 9999;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  height: auto;
  color: white;
  z-index: 1000;
  transform: translate(-50%, -50%);
  i {
    font-size: 4rem;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
  }
}

.form-row {
  font-size: 16px;
  width: 100%;

  & > select {
    font-size: 16px;
    height: 50px;
    border-color: $main-clr;
    background-color: white;
    width: 100%;
    border-radius: 0.25rem;
  }
}

.segmentSelectorContainer {
  display: flex;
  align-items: center;
  gap: 6px;

  > div:first-of-type {
    flex: 1;
  }
}
