@import "../../../styles/theme/app/variables";

.container {
  position: relative;
  padding: 24px;

  .title {
    font-weight: 300;
    padding-left: 10px;
    color: #353535;
    i {
      color: #353535;
      padding-right: 10px;
    }
  }
  .block {
    height: 327px;
    margin-bottom: 24px;
    border-radius: 4px;
    background: #ffff;
    min-height: 170px;
    box-shadow: 0px 22px 42px 0px #f0eefa;
    border-radius: 6px;
  }
  .details {
    display: block;
    text-align: center;
    color: #353535;
    line-height: 25px;
    padding: 5px 0;
    background-color: #fff;
  }
  .link {
    cursor: pointer;
  }
  .blocked {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    text-align: center;
    font-weight: 700;
    span {
      position: absolute;
      top: 187px;
      left: 0px;
      width: 100%;
      text-align: bottom;
      transform: translateY(-50%);
    }
  }
}

////////// resposive style

@media screen and (max-width: 1170px) and (min-width: 768px) {
  .container {
    .blocked {
      font-size: 9px;
    }
  }
}
// @media screen and (max-width: 1170px) and (min-width: 481px)
@media screen and (max-width: 1170px) {
  .container {
    .title {
      font-size: 13px;
    }
    .blocked {
      font-size: 13px;
      span {
        top: 42%;
      }
    }
  }
}
