.loader-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  background: #000;
  opacity: 0.5;
  left: 0px;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
}
