@import "../../../styles/theme/app/variables";

.container {
  margin: 0 auto;
  position: relative;
  width: 400px;
  & > img {
    height: 100%;
  }
  .text-cont {
    position: absolute;
    top: 247px;
    right: 40px;
    width: 73%;
    margin-left: 14%;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    padding: 20px;
    min-height: 100px;
    .paragraph {
      word-wrap: break-word;
    }
  }
}

.email {
  img {
    width: 20px;
  }
  position: absolute;
  margin-top: -25px;
  margin-left: -5px;
}

.emailContainer {
  padding-top: 35px !important;
  background-color: #ffffff45 !important;
  width: 82% !important;
  right: 23px !important;
  top: 35px !important;
}

.title {
  color: #5c5c5c !important;
}
