.navigationbar {
  text-align: center;
  border-width: thin;
  border: 1px solid rgb(207, 202, 202);
  height: 70px;
  display: flex;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
}
.nav {
  display: flex;
  margin: auto;
}

.nav a {
  // color: #c7c9cb !important;
  font-size: 16px !important;
  background-color: none !important;
  margin-right: 20px;
}
.val a {
  // background-color: white !important;
  color: #1d86f1 !important;
}
.val2 a {
  // background-color: white !important;
  color: #c7c9cb !important;
}
.nav li {
  margin-right: 71px !important;
}
.nav a:hover {
  background-color: transparent !important;
}
.nav a:focus {
  background-color: transparent !important;
  color: #1d86f1 !important;
}
.nav a:active {
  // background-color: white !important;
  color: #1d86f1 !important;
}
.android {
  font-size: 25px;
  padding-left: 16px;
  padding-right: 16px;
  border: none;
  background: none;
  width: 100%;
  margin-right: 10px;
  width: 100%;
  display: block;
}
.ios {
  font-size: 25px;
  padding-left: 16px;
  padding-right: 16px;
  border: none;
  background: none;
  height: 100%;
  width: 100%;
  display: block;
}
.sms {
  font-size: 25px;
  padding-left: 16px;
  padding-right: 16px;
  border: none;
  background: none;
  height: 100%;
  width: 100%;
  display: block;
}

.email {
  font-size: 25px;
  padding-left: 16px;
  padding-right: 16px;
  border: none;
  background: none;
  height: 100%;
  width: 100%;
  display: block;
}
