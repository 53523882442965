@import "../../../styles/theme/app/variables";

.inputRow {
  margin: 30px 0;

  .elementLabel {
    padding: 5px 0;
    font-weight: bold;
  }
  :global {
    .StripeElement {
      border: 1px solid #ccc;
      height: 40px;
      padding: 11px 10px;
      line-height: 36px;
      border-radius: 3px;
      width: 100%;
    }
  }
}
.edit {
  .inputRow {
    margin: 0 0 20px 0;
  }
}
