@import "../../../styles/theme/app/variables.scss";

.createEventModal {
  background-color: #fff;
  width: 700px !important;
  border-radius: 5px;
  font-family: $main-dashboard-font;

  button {
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    outline: none;
    font-family: $main-dashboard-font;
    cursor: pointer;
  }

  .modalHeader {
    padding: 20px;

    > button {
      color: #000;
      font-size: 30px;
    }

    .modalTitle {
      font-weight: bold;
      font-size: 20px;
    }
  }

  .modalBody {
    padding: 20px;

    .form {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
    }

    .keysContainer {
      .input {
        border: 1px solid #1f2f3f;
        font-family: inherit;
        font-size: 16px;
        font-weight: normal;
        height: 48px;
        background-color: white;
        border-radius: 5px;
        padding: 0 8px;
      }

      .input:focus,
      .input:focus-within,
      .input:active {
        border: 1px solid #033670;
        color: #033670;
      }

      .keyRow {
        display: grid;
        grid-template-columns: 1fr 1fr auto;
        grid-auto-rows: auto;
        align-items: center;
        gap: 12px;
        margin: 12px 0;
      }

      .addKeyBtn {
        width: 138px;
        color: $main-btn-clr;
        background-color: white;
        border-radius: 5px;
        border: 1px solid $main-btn-clr;
        margin: 14px 0;
      }

      .addKeyBtn:focus:enabled,
      .addKeyBtn:hover:enabled {
        background-color: $main-btn-clr-hvr;
        color: white;
      }

      .removeBtn {
        width: 48px;
        height: 48px;
        border-radius: 4px;
        background-color: #f200001a;
        display: grid;
        place-content: center;
        border: none;

        .removeBtnIcon {
          font-size: 20px;
          color: #f20000;
        }
      }
    }
  }

  .modalFooter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    margin: 30px 0;

    .cancelBtn {
      width: 80px;
      background-color: white;
      border: 1px solid $main-btn-clr;
      color: $main-btn-clr;
    }

    .cancelBtn:focus:enabled,
    .cancelBtn:hover:enabled {
      background-color: $main-btn-clr;
      color: white;
    }

    .createBtn {
      width: 80px;
      color: white;
      background-color: $main-btn-clr;
      border: 1px solid $main-btn-clr;
    }

    .createBtn:disabled {
      background-color: $main-btn-clr-disabled;
      border-color: $main-btn-clr-disabled;
      cursor: not-allowed;
    }

    .createBtn:hover:enabled {
      background-color: $main-btn-clr-hvr;
    }
  }
}

.testClass {
  color: red;
}
