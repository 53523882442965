@import "../../styles/theme/app/variables";

.wizard {
  display: flex;
  align-items: center;
  justify-content: space-evenly !important;
}

.wizard-step {
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  text-decoration: none !important;

  &--active {
    .wizard-step__icon {
      background: $main-clr;
    }
    .wizard-step__content {
      color: $main-clr;
    }
  }

  &--disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &--complete {
    .wizard-step__icon {
      background: $main-clr;
    }
    .wizard-step__content {
      text-decoration: line-through;
    }
  }

  &__icon {
    flex: 0 0 30px;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 50%;
    background: #9c9c9c;

    display: flex;
    align-items: center;
    justify-content: center;

    > * {
      color: #fff;
    }
  }

  &__content {
    color: #9c9c9c;
  }

  + .wizard-step {
    margin-left: 20px;
  }
}
