@import "../../../../styles/theme/app/variables.scss";

.our-secondary-btn {
  font-family: $main-dashboard-font;
  height: 42px;
  padding: $main-btn-padding !important;
  color: $main-btn-clr !important;
  background-color: #fff !important;
  border-color: $main-btn-clr !important;
  font-size: $main-button-font-size !important;
  border-radius: $main-btn-border-radius !important;
  transition: all $main-btn-transition-duration
    $main-btn-transition-timing-function;
  font-size: $main-btn-font-size;
  font-weight: $main-btn-font-weight;
  text-transform: capitalize;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &:hover:enabled,
  &:focus:enabled,
  &:active:enabled,
  &:visited:enabled {
    color: white !important;
    background: $main-btn-clr !important;
    border-color: $main-btn-clr !important;
  }

  &:disabled {
    background-color: $main-btn-clr-disabled !important;
    border-color: $main-btn-clr-disabled !important;
  }

  i {
    margin-right: 5px !important;
  }
}

.our-skeletonized-btn {
  display: inline-block;
  border-radius: 4px;
}

.skeleton-animation {
  animation: pulse 3s infinite ease-in-out;
  -webkit-animation: pulse 3s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}

@-webkit-keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}
