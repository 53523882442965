@import "../../../styles/theme/app/variables.scss";

.copyIcon {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 4px;
  margin-left: 4px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: $main-btn-clr;
  font-size: 25px;

  &:hover {
    color: $main-btn-clr-hvr;
  }
}
