@import "../../../styles/theme/app/variables";

// automator list page

.automator {
  background-color: #f9fafc;
  font-family: $main-dashboard-font;
  color: $main-font-clr;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 24px;
    background-color: white;
    border-top: 1px solid $main-bg-clr;
    border-bottom: 1px solid $main-bg-clr;
    position: relative;
    margin-bottom: 48px;

    .headerButtons {
      display: flex;
      align-items: center;
      gap: 16px;

      button {
        border: none;
        outline: none;
        border-radius: 5px;
        font-size: 15px !important;
        font-family: $main-dashboard-font;
        transition: color ease-in-out 150ms;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        height: 42px;
        padding: 0 14px;
      }

      button:disabled {
        background-color: $main-btn-clr-disabled;
        color: white;
        border: $main-btn-clr-disabled;
      }

      .createAutomatorBtn {
        height: 42px;
        background-color: $main-btn-clr;
        color: white;
        font-family: $main-dashboard-font;
      }

      .createAutomatorBtn:focus:enabled,
      .createAutomatorBtn:hover:enabled {
        background-color: $main-btn-clr-hvr;
      }

      .createAutomatorBtn:disabled {
        background-color: $main-btn-clr-disabled;
        color: white;
        border: $main-btn-clr-disabled;
      }
    }
  }

  // enable this pagination styling after adding it

  .paginationRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $main-dashboard-font;
    padding: 0 24px;
    margin-top: 20px;
    margin-bottom: 35px;

    .paginationIndicator {
      > p {
        color: $main-font-clr;
        font-size: 14px;
        font-weight: bold;
        font-family: inherit;
      }
    }

    .pagination {
      font-size: 14px;
      font-weight: bold;
      font-family: inherit;
    }
  }
}

// create automator

.createAutomator {
  background-color: #f9fafc;
  font-family: $main-dashboard-font;
  color: $main-font-clr;

  label {
    margin-bottom: 8px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 24px;
    background-color: white;
    border-top: 1px solid $main-bg-clr;
    border-bottom: 1px solid $main-bg-clr;
    position: relative;
    margin-bottom: 48px;

    > ol {
      padding: 0;
      margin: 0;
      > li {
        font-size: $main-breadcrumb-font-size;
        color: $gray-font-clr;
        font-family: inherit;
        font-weight: bold;

        > a {
          font-size: $main-breadcrumb-font-size;
        }

        &::before {
          color: #76767680;
          padding: 0;
          padding-left: 5px;
          padding-right: 3px;
          height: 19px;
          font-size: 16px !important;
        }

        > span {
          font-size: inherit;
          color: $main-font-clr;
          font-weight: bold;
        }

        > a > li > a {
          font-size: $main-breadcrumb-font-size;
        }
      }

      > li:last-child {
        font-size: $main-font-clr;
        font-size: $main-breadcrumb-font-size;
      }
    }
  }

  .content {
    padding: 11px 24px;

    .createAutomatorInfo {
      background-color: white;
      padding: 24px;

      .createAutomatorFieldRow {
        max-width: 660px;
      }

      .createAutomatorRadioRow {
        display: flex;
        align-items: center;
        gap: 29px;
        margin: 24px 0;
        position: relative;

        .createAutomatorSegmentsSelectionUpgrade {
          position: absolute;
          width: 170px;
          height: 40px;
          background-color: rgba(0, 0, 0, 0.2);
          z-index: 2;
          transition: all 0.5s ease-in-out;
          overflow: hidden;

          .upgrade {
            position: absolute;
            left: 50%;
            transform: translate(-50%, -100%);
            transition: all 0.5s ease-in-out;
            width: 170px;
            height: 40px;
            cursor: pointer;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            border: none;
            outline: none;
            background-color: transparent;
          }

          &:hover .upgrade {
            transform: translate(-50%, 0%);
          }

          &:hover {
            background-color: rgba(0, 0, 0, 0.9);
          }
        }

        .radio {
          display: flex;
          align-items: center;
          gap: 12px;
          margin: 0;

          > span {
            font-weight: bold;
            font-size: 15px;
            color: $main-radio-label-clr;
          }

          input {
            transform: scale(1.3);
          }
        }
      }

      .createAutomatorSegmentsSelectionContainer {
        display: grid;
        grid-template-columns: 1fr 1fr auto;
        gap: 12px;
        max-width: 660px;
        margin: 24px 0;

        .refreshBtnContainer {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          button:disabled {
            background-color: $main-btn-clr-disabled;
            color: white;
            border: $main-btn-clr-disabled;
          }

          .refreshBtn {
            height: 48px;
            width: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            border: 1px solid $main-btn-clr;
            color: $main-btn-clr;
            transition: all ease-in-out 150ms;
            border-radius: 5px;

            .icon {
              transform: scale(1.5);
            }
          }

          .refreshBtn:focus:enabled,
          .refreshBtn:hover:enabled {
            background-color: $main-btn-clr;
            color: white;
          }
        }

        div {
          > p:first-child {
            font-weight: bold;
            font-size: 15px;
            color: #4a4a4a;
          }
        }
      }

      .pressureSection {
        display: grid;
        align-items: center;
        grid-template-columns: 200px 200px;
        gap: 12px;
        max-width: 660px;

        > p:first-child {
          font-size: 16px;
          font-weight: bold;
          color: $main-radio-label-clr;
          margin: 0;
        }
      }
    }

    .channelsSelection {
      margin: 48px 0;
      .channelsSelectionTitle {
        margin: 0;
        font-size: 18px;
        font-weight: bold;
        color: #262626;
      }

      .buttonsContainer {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        background-color: inherit;
        padding: 18px 0;
        margin-left: 1px;
        border-radius: 23px;

        ul {
          width: 100%;
          border-bottom: 1px solid #7676761a;

          display: flex;
          align-items: center;
          justify-content: flex-start;

          li {
            a {
              width: 155px;
              color: rgb(131, 131, 132);
              text-align: center;
              border-radius: 1px;
              border: none;
              font-size: 1.7rem;
              font-weight: bolder;
              padding-left: 0;
              padding-right: 0;
              &:focus {
                background-color: inherit !important;
              }
              &:active {
                border: none;
                background-color: inherit !important;
              }
              &:hover {
                color: black;
                background-color: inherit;
              }
            }

            &.active {
              a {
                color: black;
              }
            }
          }
        }
      }
    }

    .sectionContainer {
      // width: 90%;

      .messagesRow {
        display: flex;
        align-items: center;
        justify-content: space-between;

        > p {
          font-size: 18px;
          font-weight: bold;
          margin: 0;
        }

        .addMessageBtn {
          font-size: 16px;
          font-weight: bold;
          border: none;
          outline: none;
          background-color: transparent;
          color: #1d86f1;
        }
      }

      .messageSection {
        margin-top: 25px;
        margin-bottom: 25px;
        border-radius: 5px;
        background-color: white;
        padding: 24px;
        position: relative;

        .removeMessageSection {
          position: absolute;
          top: 10px;
          right: 10px;

          button {
            color: #000;
            cursor: pointer;
            font-size: 20px;
            background-color: transparent;
            border: none;
            outline: none;
          }

          button:hover {
            color: red;
          }
        }

        .messageFieldRow {
          margin: 24px 0;
          max-width: 660px;

          > p:first-child {
            font-weight: bold;
            font-size: 15px;
            color: $main-font-clr;
          }

          .selectPeriodContainer {
            display: flex;
            align-items: center;
            gap: 12px;

            .selectPeriod {
              background-color: $main-input-background-clr;
              border-radius: $main-input-border-radius;
              border: 1px solid $main-input-border-clr;
              color: $main-input-clr;
              height: 48px;
              width: 100%;
              outline: none;
              font-size: 15px;
              padding: 6px 12px;
            }

            .customHours {
              > div {
                margin: 0;
              }

              p {
                margin: 0;
              }
            }
          }

          .fieldWithEmoji {
            width: 100%;
            position: relative;

            .emojiContainer {
              position: absolute;
              z-index: 99999999;
              top: 0;
              right: 0;
            }
          }
        }
      }
    }

    .buttonsContainer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 24px;
      margin: 18px 0;

      button {
        border: none;
        outline: none;
        border-radius: 5px;
        font-size: 15px !important;
        font-family: $main-dashboard-font;
        transition: color ease-in-out 150ms;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        height: 42px;
        padding: 0 14px;
      }

      button:disabled {
        background-color: $main-btn-clr-disabled;
        color: white;
        border: $main-btn-clr-disabled;
      }

      .cancelBtn {
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        color: $main-btn-clr;
        background-color: white;
        border: none;
      }

      .createBtn {
        height: 42px;
        background-color: $main-btn-clr;
        color: white;
        font-family: $main-dashboard-font;
      }

      .createBtn:focus:enabled,
      .createBtn:hover:enabled {
        background-color: $main-btn-clr-hvr;
      }

      .createBtn:disabled {
        background-color: $main-btn-clr-disabled;
        color: white;
        border: $main-btn-clr-disabled;
      }
    }
  }
}

.appPushUrlSec {
  margin-top: 10px;
  width: 100%;
}

.appPushUrlFir {
  width: 100%;
}
