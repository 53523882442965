@import "../../../../../styles/theme/app/variables";

.pagination {
  margin: 1.5rem 0;
}

.listDeepLinks {
  background-color: #f9fafc;
  font-family: $main-dashboard-font;
  color: $main-font-clr;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 24px;
    background-color: white;
    border-top: 1px solid $main-bg-clr;
    border-bottom: 1px solid $main-bg-clr;
    position: relative;
    margin-bottom: 48px;

    .headerButtons {
      display: flex;
      align-items: center;
      gap: 16px;

      button {
        border: none;
        outline: none;
        border-radius: 5px;
        font-size: 15px !important;
        font-family: $main-dashboard-font;
        transition: color ease-in-out 150ms;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        padding: 0 14px;
        height: 42px;
      }

      button:disabled {
        background-color: $main-btn-clr-disabled;
        color: white;
        border: $main-btn-clr-disabled;
      }

      .filterBtn {
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        color: $main-btn-clr;
        background-color: white;
        border: 1px solid $main-btn-clr;
      }

      .filterBtn:focus,
      .filterBtn:hover {
        background-color: $main-btn-clr-hvr;
        color: white;
      }

      .newDeepLinkBtn {
        height: 42px;
        background-color: $main-btn-clr;
        color: white;
        font-family: $main-dashboard-font;
      }

      .newDeepLinkBtn:focus:enabled,
      .newDeepLinkBtn:hover:enabled {
        background-color: $main-btn-clr-hvr;
      }

      .newDeepLinkBtn:disabled {
        background-color: $main-btn-clr-disabled;
        color: white;
        border: $main-btn-clr-disabled;
      }
    }
  }

  .paginationRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $main-dashboard-font;
    padding: 0 24px;
    margin-top: 20px;
    margin-bottom: 35px;

    .paginationIndicator {
      > p {
        color: $main-font-clr;
        font-size: 14px;
        font-weight: bold;
        font-family: inherit;
      }
    }

    .pagination {
      font-size: 14px;
      font-weight: bold;
      font-family: inherit;
    }
  }
}

.selectedFilters {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 12px 24px;

  .filterItem {
    border: 1px solid $main-btn-clr;
    border-radius: 5px;
    color: $main-font-clr;
    display: flex;
    align-items: center;
    gap: 6px;
    position: relative;
    padding: 12px 16px;

    p {
      text-transform: capitalize;
      margin: 0;
      font-size: 16px;
    }

    > p:last-child {
      color: $dark-grey-font-clr;
      font-weight: bold;
      text-transform: none;
    }

    .clearFilterButton {
      position: absolute;
      top: -14px;
      right: -14px;
      background-color: transparent;
      width: 28px;
      height: 28px;
      padding: 0 !important;
      border: none;

      .icon {
        width: 28px;
        height: 28px;
      }
    }

    .clearFilterButton:hover {
      transform: scale(0.98);
    }
  }
}

.modalDialog {
  background-color: #fff;
  width: 80% !important;
  max-width: 750px;
  border-radius: 5px;
  font-family: $main-dashboard-font;
  color: $main-font-clr;
  max-height: 80vh;
  overflow: auto;

  .modalHeader {
    border-bottom: 1px solid #eee;
    color: $main-font-clr;
    padding: 15px;

    > button {
      color: $main-font-clr;
      font-size: 25px;
    }

    .modalTitle {
      font-weight: bold;
      font-size: 16px;
      color: $main-font-clr;
    }
  }

  .modalBody {
    padding: 15px;

    button {
      border: none;
      outline: none;
      border-radius: 5px;
      font-size: 15px !important;
      font-family: $main-dashboard-font;
      transition: color ease-in-out 150ms;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      height: 42px;
      padding: 0 14px;
    }

    button:disabled {
      background-color: $main-btn-clr-disabled;
      color: white;
      border: $main-btn-clr-disabled;
    }

    .form {
      width: 100%;
      font-family: $main-dashboard-font;

      .inputsContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 8px;

        .inputContainer {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          margin: 24px 0;

          > label {
            font-size: 15px;
            color: $main-font-clr;
            font-weight: bold;

            span {
              font-size: 13px;
              color: #989898;
            }
          }

          input {
            border: 1px solid #1f2f3f;
            font-family: inherit;
            font-size: 16px;
            font-weight: normal;
            height: 48px;
            background-color: white;
            border-radius: 5px;
            padding: 0 8px;
            width: 100%;
          }

          input:focus,
          input:focus-within,
          input:active {
            border: 1px solid #033670;
            color: #033670;
          }
        }
      }
    }

    .modalFooter {
      display: flex;
      align-items: center;
      gap: 12px;
      justify-content: flex-end;
      padding: 15px;

      .closeBtn {
        color: $main-btn-clr;
        border: 1px solid $main-btn-clr;
        background-color: white;
      }

      .closeBtn:focus:enabled,
      .closeBtn:hover:enabled {
        color: white;
        background-color: $main-btn-clr;
      }

      .applyFilterBtn {
        color: white;
        background-color: $main-btn-clr;
        border: 1px solid $main-btn-clr;
      }

      .applyFilterBtn:focus:enabled,
      .applyFilterBtn:hover:enabled {
        background-color: $main-btn-clr-hvr;
      }
    }
  }
}
