@import "../../styles/theme/app/variables.scss";

.breadcrumb {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 22px;

  .inactive {
    color: $gray-font-clr;
    font-size: 16px;
    font-weight: 700;
  }

  .active {
    color: $main-font-clr;
    font-size: 16px;
    font-weight: 700;
  }

  .beta {
    color: #ff0000;
    font-size: 14px;
    font-weight: 700;
  }
}
