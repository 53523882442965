@import "../../../styles/theme/app/variables";

.newField {
  width: 50%;
  height: 100%;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.facebookConnectBtn {
  border-color: #4267b2 !important;
  background: #4267b2 !important;
  color: #fff !important;
  font-weight: 700;
  border-width: 0px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  margin: 15px 0;
  outline: none;
  border-radius: 5px;
}

.note {
  font-size: 14px;
  color: green;
}
.imgSizeError {
  color: red;
  position: relative;
  text-align: c;
  top: 33px;
  width: 300px;
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  height: auto;
  color: white;
  z-index: 1000;
  transform: translate(-50%, -50%);
  i {
    font-size: 4rem;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
  }
}
.loader-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  background: #000;
  opacity: 0.5;
  left: 0px;
  z-index: 9999;
}

.generatebutton {
  margin-left: 0px;
  margin-top: 0;
  font-size: 16px !important;
  color: #ffffff !important;
  background-color: #1d86f1 !important;
  border-radius: 4px !important;
  padding: 18px !important;
}
.updatee {
  color: #be323e;
}
.clearButton {
  padding: 15px !important;
  color: #1d86f1 !important;
  border-color: #1d86f1 !important;
  font-size: 16px !important;
  border-radius: 6px;

  &:hover,
  &:focus,
  &:active,
  &:visited {
    color: #1d86f1 !important;
  }
}

.deleteButton {
  background-color: #ffffff !important;
  color: #1d86f1 !important;
  font-size: 16px !important;
  border-color: #1d86f1 !important;
  padding: 12px !important;
  border-radius: 4px !important;
  margin-bottom: 30px !important;
  margin-left: 26px !important;
}
.regenerateButton {
  background-color: #1d86f1 !important;
  color: #fff !important;
  font-size: 16px;
  border-color: #1d86f1 !important;
  padding: 14px !important;
  border-radius: 4px;
  min-width: 105px;
  margin-left: 25px;
}

.populateButton {
  padding: 15px !important;
  color: #fff !important;
  font-size: 16px !important;
  border-radius: 6px;
  margin-right: 10px !important;
  &:hover,
  &:focus,
  &:active,
  &:visited {
    color: #fff !important;
  }
}
.projectIcon {
  margin-bottom: 35px;
}
.cancelButton {
  float: left;
  width: 75px;
  height: 30px;
  margin-top: 0px;
  margin-bottom: 80px;
}
.savebutton {
  float: right;
  width: 75px;
  height: 30px;
  margin-top: 0px;
  margin-bottom: 20px;
}
.alert {
  position: fixed;
  top: 33.5%;
  left: 44%;
  width: 23.5%;
  background-color: #013874;
  color: #ffffff !important;
  z-index: 5;
  @media only screen and (max-width: 415px) {
    width: 100%;
    margin: auto;
    position: absolute;
    top: 40%;
    left: 0%;
  }
  button {
    opacity: 1 !important;
  }
}
.Alertbutton {
  background-color: #ffffff !important;
  color: #013874 !important;
  border-style: solid;
  border-color: #ffffff !important;
  margin: 10px;
}
.Alertbutton:hover {
  background-color: #013874 !important;
  color: #ffffff !important;
  border-color: #ffffff;
}

.ConfirmButton {
  background-color: #ffffff !important;
  color: #013874 !important;
  border-style: solid;
  border-color: #ffffff !important;
  float: right;
}
.ConfirmButton:hover {
  background-color: #013874 !important;
  color: #ffffff !important;
  border-color: #ffffff;
}
.deletebtn {
  color: #cc3e4a;
  background-color: #fff;
  border-color: #be323e;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 13px;
  line-height: 1.52857;
  border-radius: 2px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.CancelButton {
  background-color: #ffffff !important;
  color: #013874 !important;
  border-style: solid;
  border-color: #ffffff !important;
  float: left;
}
.CancelButton:hover {
  background-color: #013874 !important;
  color: #ffffff !important;
  border-color: #ffffff;
}

.settingContainerSty {
  margin-bottom: 80px;
}

.cancelButtonEdit {
  background-color: mediumseagreen !important;
  color: #fff !important;
  font-size: 16px;
  border-color: #1d86f1 !important;
  padding: 7px;
  border-radius: 4px;
  position: relative;
  height: 48px;
  right: 19px !important;
  min-width: 105px;
  bottom: 16px !important;
}

.newDeleteButton {
  padding: 5px 15px !important;
  color: #1d86f1 !important;
  border-color: #1d86f1 !important;
  font-size: 16px !important;
  border-radius: 6px;

  &:hover,
  &:focus,
  &:active,
  &:visited {
    color: #1d86f1 !important;
  }
}

.new-btn-settings {
  padding: 10px 30px !important;
  margin-right: 10px !important;
  border-radius: 4px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle {
  display: flex;
  gap: 2rem;
  align-items: center;
  margin: 2rem 0;

  > p:first-child {
    font-size: 16px;
    color: rgb(3, 13, 24);
    font-weight: 100;
    margin: 0;
    padding: 0;
    text-transform: capitalize;
  }
}
