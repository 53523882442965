@import "../.././../../styles/theme/app/variables.scss";

.unlayerTemplateStep {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .unlayerTemplateStep__image {
    margin: 0 auto;
    margin-bottom: 16px;
  }

  .unlayerTemplateStep__title {
    color: $dark-medium-font-clr;
    font-size: 24px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 16px;
    text-align: center;
  }

  .unlayerTemplateStep__buttonsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 36px;
  }
}
