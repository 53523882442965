@import "../../styles/theme/app/variables.scss";

.camapign-wizard {
  border-radius: 8px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.camapign-wizard-steps {
  display: flex;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #fcfcfc;
  font-weight: bold;
  border-bottom: 1px solid #e2e2e2;
}

.wizard-step {
  // text-align: center;
  font-size: 1.5rem;
  padding: 2rem 2rem;
  color: $dark-grey-font-clr;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all $main-animation-duration $main-animation-timing-function;

  &:hover {
    background-color: #e8f3fe;
    color: $dark-font-clr;
  }

  & > div {
    margin-right: 1rem !important;
    height: 2.2rem !important;
    width: 2.2rem !important;
    border: 1px solid #000000;
    border-radius: 100%;
    cursor: pointer !important;
  }

  & > p {
    font-size: 2rem;
    margin-bottom: 0;
  }

  & > .active-radio {
    background-color: $main-clr;
    border: none;
  }
}
