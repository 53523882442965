.appboost-dashboard {
  padding: 25px;
}

.appboost-dashboard-summary-card {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: row;
  background-color: #fff;
  border-radius: 6px;
  padding: 15px 10px;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  height: 100px;
}

.appboost-dashboard-summary-card-label {
  color: #575d65;
  font-size: 16px;
  font-weight: bold;
  & > div {
    span::after {
      width: 320px;
    }
  }
}

.appboost-dashboard-summary-card-number {
  color: #1d86f1;
  font-size: 20px;
}

.appboost-dashboard-summary-card > i {
  font-size: 18px;
  width: 50px;
  height: 50px;
  text-align: center;
  background-color: #bbdbfb;
  color: #1d86f1;
  font-weight: bold;
  border-radius: 50%;
  padding-top: 15px;
  margin-right: 10px;
}

.appboost-dashboard-summary-card-end-flex {
  margin-left: auto;

  i {
    font-size: 15px;
    width: 30px;
    height: 30px;
    text-align: right;
  }

  p {
    font-size: 16px;
    padding-top: 5px;
  }

  .green {
    color: #00d592;
  }

  .red {
    color: #ff0000;
  }
}

.skeleton-icon {
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 50%;
  padding-top: 15px;
  margin-right: 10px;
  animation: pulse 3s infinite ease-in-out;
  -webkit-animation: pulse 3s infinite ease-in-out;
}

.skeleton-paragraph {
  height: 20px;
  width: 100px;
  border-radius: 5px;
  margin-bottom: 10px;
  animation: pulse 3s infinite ease-in-out;
  -webkit-animation: pulse 3s infinite ease-in-out;
}

.skeleton-number {
  height: 20px;
  width: 70px;
  border-radius: 5px;
  animation: pulse 3s infinite ease-in-out;
  -webkit-animation: pulse 3s infinite ease-in-out;
}

.skeleton-growth {
  height: 20px;
  width: 40px;
  border-radius: 5px;
  margin-top: 18px;
  animation: pulse 3s infinite ease-in-out;
  -webkit-animation: pulse 3s infinite ease-in-out;
}

.appboost-dashboard-chart {
  border-radius: 8px;
  // padding: 15px 15px;
  margin-top: 15px;
  height: 452px;
  background-color: #fff;

  & > div:first-of-type > div:first-of-type > p:first-of-type > div {
    span::after {
      width: 300px;
    }
  }
}

.custom-tooltip-charts {
  background-color: #fff;
  border-radius: 4px;
  padding: 10px;
  p {
    color: #000;
    font-size: 1.7rem;
    font-weight: bold;
  }
  span {
    color: #1d86f1;
  }
}

.appboost-dashboard-chart-labels {
  display: flex;
  background-color: rgba(0, 73, 165, 0.03);
  border-radius: 8px 8px 0px 0px;
  padding: 18px 32px;
  div:not(:first-of-type) {
    margin-left: auto;
    margin-top: 10px;

    p {
      display: inline-block;
      color: #cbcbcb;
      font-size: 16px;
      border: none;
      margin-right: 15px;
      cursor: pointer;
    }

    p.active {
      color: #1d86f1;
      font-size: 16px;
    }
  }
}

.appboost-dashboard-chart p:first-of-type {
  color: #092848;
  font-size: 16px;
  font-weight: 700;
}

.appboost-dashboard-chart p:not(:first-of-type) {
  color: #cecece;
  font-size: 14px;
}

.skeleton-label-small {
  height: 20px;
  width: 110px;
  border-radius: 5px;
  animation: pulse 3s infinite ease-in-out;
  -webkit-animation: pulse 3s infinite ease-in-out;
}

.skeleton-label-large {
  height: 20px;
  width: 140px;
  border-radius: 5px;
  animation: pulse 3s infinite ease-in-out;
  -webkit-animation: pulse 3s infinite ease-in-out;
}

.skeleton-label-btn {
  height: 20px;
  width: 70px;
  border-radius: 5px;
  animation: pulse 3s infinite ease-in-out;
  -webkit-animation: pulse 3s infinite ease-in-out;
}

.skeleton-label-chart {
  height: 300px;
  width: 100%;
  border-radius: 5px;
  animation: pulse 3s infinite ease-in-out;
  -webkit-animation: pulse 3s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}

@-webkit-keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}

.not-subscribed-section {
  margin-top: 20px;
}

.not-subscribed-heading {
  font-size: 20px;
  color: #5c5c5c;
  font-weight: 700;
}

.not-subscribed-options {
  margin-top: 20px;
}

.card-not-sub {
  margin-top: 1rem;
  background-color: #fff;
  padding: 25px;
  margin-right: 30px;
  height: 100%;
  position: relative;
  & > p:first-of-type {
    color: #5c5c5c;
    font-weight: bold;
    font-size: 18px;
  }
  & > p:not(:first-of-type) {
    color: #5c5c5c;
    font-size: 14px;
    width: 70%;
  }
  & > div {
    background-color: #bbdbfb;
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    width: 42px;
    height: 42px;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    & > i {
      line-height: 42px;
      font-size: 14px;
      font-weight: normal;
      color: #1d86f1;
    }
  }
}

.not-subscribed-options > div:last-of-type {
  margin-right: 0px;
}

.more-info-icon {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding-right: 1px;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  background-color: #bbdbfb !important;
  color: #1d86f1 !important;
}
