.suggestBestTimeBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 50px;
  border: none;
  padding: 16px;
  border-radius: 4px;
  background: linear-gradient(91deg, #7ba0ca 0.49%, #f085a2 107.88%);
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}
