@import "../../styles/theme/app/variables.scss";

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 12px;
}

.btn {
  height: 42px;
  border-radius: 4px;
  font-size: 15x;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  outline: none;
  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.cancelBtn {
  background-color: white;
  border: 1px solid $main-btn-clr;
  color: $main-btn-clr;
}

.cancelBtn:focus:enabled,
.cancelBtn:hover:enabled {
  background-color: $main-btn-clr;
  color: white;
}

.criticalConfirm {
  background-color: $main-delete-clr;
  border: 1px solid $main-delete-clr;
  color: white;
}

.criticalConfirm:focus:enabled,
.criticalConfirm:hover:enabled {
  background-color: $main-delete-clr-hvr;
  border: 1px solid $main-delete-clr-hvr;
}

.normalConfirm {
  background-color: $main-btn-clr;
  border: 1px solid $main-btn-clr;
  color: white;
}

.normalConfirm:focus:enabled,
.normalConfirm:hover:enabled {
  background-color: $main-btn-clr-hvr;
  border: 1px solid $main-btn-clr-hvr;
}

.description {
  color: $main-font-clr;
  line-height: 28px;
  font-size: 16px;
  font-weight: bold;
}
