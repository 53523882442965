@import "../../styles/theme/app/variables.scss";

.date-filter-container {
  button {
    border: 1px solid $main-btn-clr;
    border-radius: 5px;
  }

  font-family: $main-dashboard-font;
  & > div {
    position: relative;

    & > button {
      padding: 5px 15px !important;
      color: #fff !important;
      background-color: $main-btn-clr;
      font-size: 18px !important;
      border-radius: 6px;
      text-transform: capitalize;
      border-color: $main-btn-clr;

      &:hover,
      &:focus,
      &:active,
      &:visited {
        color: #fff !important;
        background: $main-btn-clr-hvr;
        border-color: $main-btn-clr-hvr;
      }

      & i:first-of-type {
        margin-right: 5px;
        font-size: 18px;
      }
      & i:not(:first-of-type) {
        font-size: 18px;
        margin-left: 5px;
      }
    }
    & > div {
      top: 100%;
      background-color: #fff;
      position: absolute;
      left: auto;
      right: 0;
      z-index: 1000;
      padding: 10px;
      margin: 2px 0 0;
      list-style: none;
      font-size: 13px;
      text-align: left;
      border-radius: 5px;
      border: 1px solid #ccc !important;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      background-clip: padding-box;
      & > div:first-of-type {
        display: flex;
        & > div:first-of-type {
          flex-grow: 1;
          margin-right: 10px;
          position: relative;
          input {
            width: 120px;
            padding: 6px 5px;
            background: #fefefe 0% 0% no-repeat padding-box;
            border: 0.5px solid #c7c9cb;
            border-radius: 2px;
          }
          input::placeholder {
            color: #a9a9a9;
          }
          & > i {
            position: absolute;
            color: #a9a9a9;
            left: 78px;
            top: 10px;
          }
        }
        & > div:not(:first-of-type) {
          flex-grow: 1;
          margin-right: 10px;
          position: relative;
          input {
            width: 120px;
            padding: 6px 5px;
            background: #fefefe 0% 0% no-repeat padding-box;
            border: 0.5px solid #c7c9cb;
            border-radius: 2px;
          }
          input::placeholder {
            color: #a9a9a9;
          }
          & > i {
            position: absolute;
            color: #a9a9a9;
            left: 60px;
            top: 10px;
          }
        }
      }
      & > div:not(:first-of-type) {
        margin-top: 10px;
        width: 250px;
        button {
          border-radius: 5px;
        }

        button:focus {
          background-color: $main-btn-clr;
          color: white;
        }

        button:hover {
          background-color: $main-btn-clr-hvr;
          color: white !important;
          border-color: $main-btn-clr-hvr;
        }
      }
    }
  }

  .applyBtnContainer {
    > button {
      background-color: $main-btn-clr;
      color: white;
    }
  }

  .cancelBtnContainer {
    > button {
      border: 1px solid $main-btn-clr !important;
    }
  }

  .btn-primary {
    background-color: $main-btn-clr;
  }
}

.hidden-filter {
  display: none;
}

.skeleton-date-filter {
  width: 140px;
  height: 40px;
  border-radius: 4px;
  animation: pulse 3s infinite ease-in-out;
  -webkit-animation: pulse 3s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}

@-webkit-keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}

@media all and (max-width: 768px) {
}
