@import "../../../../../styles/theme/app/variables";

.whatsAppConfig {
  font-family: $main-dashboard-font;
  padding-top: 15px;

  .vendorSelector {
    max-width: 600px;

    > label {
      font-weight: bold;
    }
  }

  .FieldsGridContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
    // max-width: 600px;
  }

  .fieldContainer {
    margin: 40px 0;

    > label {
      font-weight: bold;
      font-size: 16px;
    }
  }
}

.facebookConnectBtn {
  border-color: #4267b2 !important;
  background: #4267b2 !important;
  color: #fff !important;
  font-weight: 700;
  border-width: 0px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  margin: 15px 0;
  outline: none;
  border-radius: 5px;
}
