@import "../../../../styles/theme/app/variables";
.modalStyle {
  color: black;
  & > div {
    background-color: white !important;
    width: 530px !important;
  }
  span {
    color: black;
  }
  h4 {
    color: #a6a2a2;
    font-weight: bold;
  }
}
.dateRange {
  text-align: center;
  padding-top: 20px;
}
.modalFooter {
  // border-top: 1px lightgrey solid !important;
  /* margin-top: 23px; */
  width: 95%;
  margin: auto;
  margin-top: 23px;
  // padding-top: 10px;
  // padding-bottom: 10px;
}

.modalHeader {
  border-bottom: 1px solid lightgrey !important;
  box-shadow: 0 5px 10px -5px lightgrey !important;
  margin-bottom: 20px;
}

.modalCancel {
  border-color: #a6a2a2 !important;
  color: #a6a2a2 !important;
  font-weight: bold;
}

.modalConfirm {
  border-color: #2686f1 !important;
  color: white !important;
  font-weight: bold;
  background-color: #2686f1 !important;
}

.modalConfirCritical {
  border-color: #cc3e4a !important;
  color: white !important;
  font-weight: bold;
  background-color: #be323e !important;
}

.description {
  color: grey;
  line-height: 28px;
  font-size: 16px;
  font-weight: bold;
}

.modalBody {
  height: auto;
  min-height: 420px;
  padding-top: unset;
  padding-bottom: unset;
}

.modal-dialog {
  width: 530px !important;
}

.row {
  padding-right: 10px;
  padding-left: 10px;
  line-height: 5rem;
  border-bottom: 1px solid lightgray;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
}

.row span:first-child {
  color: #a6a2a2;
}

.row:last-child {
  border-bottom: unset;
}

.hint {
  color: #a6a2a2;
}
