.lang-selector {
  position: relative;
  background: #fff;
  padding-top: 10px;
  .lang-container {
    margin: 0px 5px 5px;
    background-color: #e8f3fe;
    padding: 10px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    .langs-btns-cont {
      display: flex;
      flex-wrap: wrap;
    }

    .add-lang-btn {
      margin-left: auto;
    }
  }
}
