.input-container {
  position: relative;
}

.input-form {
  position: relative;
  height: 5rem !important;
  font-size: 1.5rem;
}

.search-input {
  height: 5rem !important;
  font-size: 1.5rem;
}

.my-form-label {
  font-size: 1.6rem;
  color: #575d65;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: bold;
}
.hintSty {
  text-align: right;
  color: #a2a09e;
  margin-top: 5px;
  margin-bottom: 0 !important;
  a,
  a:link {
    text-decoration: none;
  }
}

.search-btn {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  & > button {
    color: #fff !important;
    background-color: #1d86f1 !important;
    border-radius: 2px !important;

    &:hover,
    &:focus,
    &:active,
    &:visited {
      color: #fff !important;
      background: #1d86f1 !important;
    }
  }
}
