@import "../../../styles/theme/app/variables.scss";

.wrapper {
  height: 5rem;
  width: 100%;
  position: relative;
  background: rgba(255, 255, 255, 0.5);
  input {
    height: 5rem;
    border-radius: 5px !important;
    font-size: 1.5rem !important;
  }
  & > div > span:last-of-type {
    text-align: right !important;
    margin-left: auto;
  }
}
.notification-icon {
  position: absolute;
  top: 1.4rem !important;
  right: 1.5rem !important;
  font-size: 2.5rem !important;
  cursor: pointer;
  z-index: 10;
}
.times-icon {
  position: absolute;
  top: 1.4rem !important;
  cursor: pointer;
  z-index: 10 !important;
  right: 4.8rem !important;
  font-size: 2.5rem !important;
}

.space {
  margin-top: 10px;
}

.readOnly {
  text-overflow: ellipsis;
  overflow: hidden;
  height: 36px;
  width: 100%;
  padding: 0 10px;
  border: 1px solid #e5e5e5;
  padding-right: 75px;
}

.file {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  height: 36px;
  width: 44px;
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
}
