@import "../../styles/theme/app/variables.scss";

.dataTable {
  .dataTableHeader {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;
    justify-content: flex-start;
    flex-direction: row-reverse;
    padding: 0 24px;

    button {
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $main-dashboard-font;
      margin: 0;
      font-weight: normal;
      outline: none;
      gap: 12px;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      transition: color ease-in-out 150ms;
      padding: 0 14px;

      .icon {
        font-size: 20px;
      }
    }

    button:disabled {
      background-color: $main-btn-clr-disabled;
      color: white;
      border: $main-btn-clr-disabled;
    }

    .multiActionBtnContainer {
      margin-right: auto;
      position: relative;

      .multiActionBtn {
        font-size: 15px;
        font-weight: normal;
        background-color: $main-btn-clr;
        border-color: $main-btn-clr;
        color: white;
        border-radius: 5px;
        padding: 0 24px;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;

        .count {
          font-weight: bold;
          font-size: 13px;
          color: white;
          background-color: #ffffff4d;
          border-radius: 25px;
          width: 28px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .multiActionBtn:focus,
      .multiActionBtn:hover {
        background-color: $main-btn-clr-hvr;
      }
    }
  }

  .sticky {
    th {
      position: sticky;
      top: 0;
      background: white;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    }
  }

  .gearBtnContainer {
    position: relative;

    .gearBtn {
      width: 185px;
      color: $main-btn-clr;
      background-color: white;
      border: 1px solid $main-btn-clr;
      font-size: $main-button-font-size;
    }

    .gearBtn:focus,
    .gearBtn:hover {
      background-color: $main-btn-clr-hvr;
      color: white;
    }
  }

  .exportBtnContainer {
    .exportBtn {
      padding: 0 18px;
      color: white;
      border: 1px solid $main-btn-clr;
      background-color: $main-btn-clr;
    }

    .exportBtn:focus:enabled,
    .exportBtn:hover:enabled {
      background-color: $main-btn-clr-hvr;
      color: white;
    }
  }

  .importBtnContainer {
    .importBtn {
      width: 123px;
      color: $main-btn-clr;
      border: 1px solid $main-btn-clr;
      background-color: white;
    }

    .importBtn:focus,
    .importBtn:hover {
      background-color: $main-btn-clr-hvr;
      color: white;
    }
  }

  .dataTableContainer {
    max-height: calc(100vh - 375px);
    min-height: 450px;
    margin: 16px 24px;
    overflow: auto;
    border-radius: 10px;

    .table {
      --table-cell-padding: 12px;
      --even-row-bg-clr: #9898981a;
      width: 100%;

      .tableHead {
        border-top: 1px solid $border-gray-clr;
        border-bottom: 1px solid $border-gray-clr;
        background: #fff;

        overflow: hidden;

        > tr {
          > th:first-child {
            border-top-left-radius: 10px;
            -moz-border-top-left-radius: 10px;
            -webkit-border-top-left-radius: 10px;
          }

          > th:last-child {
            border-top-right-radius: 10px;
            -moz-border-top-right-radius: 10px;
            -webkit-border-top-right-radius: 10px;
          }

          > th {
            font-family: $main-dashboard-font;
            color: $th-clr;
            font-size: $main-font-size;
            text-transform: capitalize;
            padding: var(--table-cell-padding);

            .tableHeadCell {
              display: flex;
              align-items: center;
              gap: 6px;
            }
          }

          > th:last-child {
            text-align: center;
          }
        }
      }

      .tableBody {
        overflow: hidden;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        -moz-border-bottom-right-radius: 10px;
        -moz-border-bottom-left-radius: 10px;
        -webkit-border-bottom-right-radius: 10px;
        -webkit-border-bottom-left-radius: 10px;
        overflow: hidden;

        > tr:nth-child(odd) {
          border-bottom: 1px solid $border-gray-clr;
        }

        > tr:nth-child(even) {
          border-bottom: 1px solid $border-gray-clr;
        }

        > tr {
          height: 60px;

          > td {
            font-family: $main-dashboard-font;
            padding: var(--table-cell-padding);
            color: $main-font-clr;
            font-size: $main-font-size;
            text-overflow: ellipsis;
            vertical-align: middle;

            .tableCell {
              display: flex;
              align-items: center;
              gap: 6px;
              width: fit-content;

              &.clickable {
                cursor: pointer;
                transition: color ease-in-out 150ms;

                &:hover {
                  color: $main-btn-clr;
                }
              }
            }
          }
        }

        > tr:last-child {
          > td:first-child {
            border-bottom-left-radius: 10px;
            -moz-border-bottom-left-radius: 10px;
            -webkit-border-bottom-left-radius: 10px;
          }

          > td:last-child {
            border-bottom-right-radius: 10px;
            -moz-border-bottom-right-radius: 10px;
            -webkit-border-bottom-right-radius: 10px;
          }
        }
      }

      .dataTableCheckbox {
        transform: scale(1.3);
      }
    }
  }

  // .dataTableContainer:hover {
  //   overflow: auto;
  // }

  // scroll bar styling for data table
  // .dataTableContainer::-webkit-scrollbar {
  //   width: 10px;
  //   height: 10px;
  // }

  // .dataTableContainer::-webkit-scrollbar-track {
  //   background-color: darkgrey;
  // }

  // .dataTableContainer::-webkit-scrollbar-thumb {
  //   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  // }

  .rotate-icon-90 {
    margin-left: 10px;
    cursor: pointer;
    display: initial;
  }
}

.download-icon {
  vertical-align: middle;
  height: 100%;
}

.mobile-preview {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 4px;
  margin-left: 4px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: $main-btn-clr;
  font-size: 25px;

  &:hover {
    color: $main-btn-clr-hvr;
  }
}

// loaders

.skeletonized-btn-gear {
  width: 185px;
  height: 42px;
  border-radius: 5px;
}

.skeletonized-btn-csv {
  width: 123px;
  height: 42px;
  border-radius: 5px;
}

.skeletonized-btn-upload {
  width: 123px;
  height: 42px;
  border-radius: 5px;
}

.skeletonized-header-label {
  width: 100%;
  height: 60px;
}

.skeleton-animation {
  animation: pulse 3s infinite ease-in-out;
  -webkit-animation: pulse 3s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }

  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }

  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}

@-webkit-keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }

  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }

  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}

.more-info-icon {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding-right: 1px;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  background-color: #bbdbfb !important;
  color: #1d86f1 !important;
}

.extraDetails {
  padding: 1rem;
  color: #000;

  p {
    margin: 0;
  }

  > div {
    display: grid;
    grid-template-columns: 140px auto;
    gap: 1rem;
    align-items: center;
    border-bottom: 1px solid #eee;

    > p {
      margin: 0;
      padding: 0;
      font-size: 1.5rem;
    }

    > p:nth-child(2) {
      word-break: break-all;
    }
  }

  > div:last-child {
    border: none;
  }
}

.previewModal {
  > div:nth-child(2) {
    background-color: transparent;
    box-shadow: none;
  }
}
